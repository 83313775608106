import React from 'react';
import { Detector } from 'react-detect-offline';
import { Modal } from 'semantic-ui-react';

import WarningOfflineMessage from 'components/molecules/WarningOfflineMessage';

export const ModalOfflineWarning = React.memo(() => {
  const pollingSetting = {
    url: `${process.env.REACT_APP_API_URL}/status`,
    interval: 10000
  };

  return (
    <Detector
      polling={pollingSetting}
      render={({ online }) =>
        !online ? (
          <Modal open size='mini' dimmer='blurring'>
            <Modal.Content>
              <WarningOfflineMessage />
            </Modal.Content>
          </Modal>
        ) : null
      }
    />
  );
});

export default ModalOfflineWarning;
