import * as types from './types';

const ACTION_HANDLERS = {
  [types.COMPANIES_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.COMPANIES_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.COMPANIES_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.COMPANIES_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.COMPANIES_SET_COMPANY_DATA]: (state, action) => ({
    ...state,
    companiesList: action.payload.companiesList
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  companiesList: []
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
