import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const UsersService = {
  getAllUsers: function () {
    return CallService.authorizedCall('GET', '/users').then(jsonResponseHandler);
  }
};

export default UsersService;
