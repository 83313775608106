import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import history from './shared/scripts/history';
import PrivateRoute from './components/molecules/PrivateRoute';

import Dashboard from './components/pages/Dashboard';
import NotFound from './components/pages/NotFound';
import Login from './components/pages/Login';
import ReportGroups from './components/pages/ReportGroups';
import Reports from './components/pages/Reports';
import Clients from './components/pages/Clients';
import Markets from './components/pages/Markets';
import UserEmailConfigurations from './components/pages/UserEmailConfigurations';
import Companies from './components/pages/Companies';
import MantaVideoComponents from './components/pages/MantaVideoComponents';
import UserApplications from './components/pages/UserApplications';

import './App.scss';
import 'semantic-ui-css/semantic.min.css';

const mapStateToProps = state => ({ ...state });
class App extends Component {
  render () {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path='/' component={Dashboard} />
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/reports' component={ReportGroups} />
          <PrivateRoute exact path='/reports/:id' component={Reports} />
          <PrivateRoute exact path='/clients' component={Clients} />
          <PrivateRoute exact path='/markets' component={Markets} />
          <PrivateRoute exact path='/useremailconfigurations' component={UserEmailConfigurations} />
          <PrivateRoute exact path='/companies' component={Companies} />
          <PrivateRoute exact path='/mantavideocomponents' component={MantaVideoComponents} />
          <PrivateRoute exact path='/userapplications' component={UserApplications} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default connect(mapStateToProps)(App);
