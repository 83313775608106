import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ErrorBoundary from 'components/atoms/ErrorBoundary';
import MainMenu from 'components/organisms/MainMenu';
import ModalOfflineWarning from 'components/organisms/ModalOfflineWarning';

import styles from './NotFoundTemplate.module.scss';

export const NotFoundTemplate = ({ children }) => (
  <div className={styles.root}>
    <MainMenu isLogoutBtnVisible={false} />

    <ModalOfflineWarning />

    <Grid padded stretched centered verticalAlign='middle' className={styles.content}>
      <Grid.Row>
        <Grid.Column width={10} verticalAlign='middle'>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

NotFoundTemplate.propTypes = {
  children: PropTypes.node
};

export default NotFoundTemplate;
