import * as types from './types';

const ACTION_HANDLERS = {
  [types.REPORTS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.REPORTS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.REPORTS_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.REPORTS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.REPORTS_SET_REPORTS]: (state, action) => ({
    ...state,
    reportData: action.payload.reportData
  }),
  [types.REPORTS_SET_GROUP_ID]: (state, action) => ({
    ...state,
    groupId: action.payload.groupId
  }),
  [types.REPORTS_SET_REMOVE_ITEM_FROM_STATE]: (state, action) => ({
    ...state,
    reportData: {
      ...state.reportData,
      reports: state.reportData.reports.filter(item => item.id !== action.payload.id)
    }
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  reportData: {},
  groupId: null
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
