import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';
import * as warningConstants from 'shared/constants/warningConstants';

class ReportGroups extends React.PureComponent {
  state = {
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    id: null,
    initialValues: {},
    createEditHeaderContent: '',
    createEditBtnContent: '',
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadReportGroups();
  };

  handleReportGroupViewClick = id => this.props.history.push(`/reports/${id}`);

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };

  handleEditModalOpen = async id => {
    this.setState({
      id: id,
      isEditModalOpen: true,
      initialValues: {
        ...this.props.loadSelectedGroup(id)
      },
      createEditHeaderContent: 'Edit group',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
    await this.props.loadMarkets();
  };
  handleCreateModalOpen = async () => {
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create new group',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });
    await this.props.loadMarkets();
  };
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };
  handleDeleteModalOpen = id =>
    this.setState({
      isDeleteModalOpen: true,
      id: id
    });
  handleDeleteModalClose = () => {
    this.resetModalState();
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDeleteSubmit = () => {
    const { loadReportGroupToDelete } = this.props;
    const { id } = this.state;

    loadReportGroupToDelete(id, () => {
      if (!this.isErrorDefined()) {
        this.handleDeleteModalClose();
      }
    });
  };
  handleSubmitNew = data => {
    const { loadCreateGroup } = this.props;

    loadCreateGroup(data, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };
  handleSubmitUpdate = data => {
    const { loadReportGroupToUpdate } = this.props;
    const { id } = this.state;
    const params = { data, id };

    loadReportGroupToUpdate(params, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };

  handleSearchUpdate = values => this.props.loadFilterString(values.search);

  render () {
    const {
      isDeleteModalOpen,
      isEditModalOpen,
      initialValues,
      createEditHeaderContent,
      createEditBtnContent,
      handleCreateEditAction
    } = this.state;
    const { isDataLoading, isRequestLoading, groups, error, markets } = this.props;
    const contentHeaderActions = [
      {
        content: 'Create new group',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];
    const actionsList = [
      {
        content: 'Edit Reports',
        type: 'standard',
        action: this.handleReportGroupViewClick
      },
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteModalOpen
      }
    ];
    const deleteActionList = [
      {
        content: 'Cancel',
        type: 'standard',
        action: this.handleDeleteModalClose
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteSubmit
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Group name',
        name: 'name',
        required: true
      },
      {
        type: 'array',
        label: 'Market',
        name: 'marketId',
        required: true,
        options: markets,
        loading: isEmpty(markets)
      }
    ];
    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'marketId',
        label: 'Market ID'
      },
      {
        name: 'name',
        label: 'Group Name'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          isEditModalOpen={isEditModalOpen}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
          fieldList={fieldList}
          handleCreateModalClose={this.handleCreateModalClose}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}

          handleDeleteModalClose={this.handleDeleteModalClose}
          deleteActionList={deleteActionList}
          isDeleteModalOpen={isDeleteModalOpen}
          deleteContent={warningConstants.WARNING_DELETE_GROUP}

          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['Report Groups']}
          contentHeaderActions={contentHeaderActions}

          tableActionsList={actionsList}
          dataList={groups}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          error={error}
        />
      </DashboardTemplate>
    );
  }
}

ReportGroups.propTypes = {
  loadFilterString: PropTypes.func.isRequired,
  loadMarkets: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  loadReportGroupToUpdate: PropTypes.func.isRequired,
  loadSelectedGroup: PropTypes.func.isRequired,
  loadCreateGroup: PropTypes.func.isRequired,
  loadReportGroupToDelete: PropTypes.func.isRequired,
  loadReportGroups: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  markets: PropTypes.array.isRequired
};

export default ReportGroups;
