import { connect } from 'react-redux';

import GroupReports from '../components/Reports';
import {
  loadReports,
  loadReportToDelete,
  loadCreateReport,
  loadSelectedReport,
  loadReportToUpdate,
  loadResetError,
  loadReportGroupId
} from '../redux/operations';
import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    reportData: selector.getReports(state),
    groupId: selector.getReportGroupId(state)
  }),
  {
    loadReportGroupId,
    loadResetError,
    loadReportToUpdate,
    loadSelectedReport,
    loadReports,
    loadReportToDelete,
    loadCreateReport
  }
)(GroupReports);
