import React from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import DataTable from 'components/organisms/DataTable';
import ModalMessage from 'components/organisms/ModalMessage';
import ModalCreateEdit from 'components/organisms/ModalCreateEdit';
import ContentHeader from 'components/molecules/ContentHeader';

import styles from './TableView.module.scss';

export const TableView = React.memo(
  ({
    isDataLoading,
    isRequestLoading,
    contentHeaderActions,
    dataList,
    tableActionsList,
    handleDeleteModalClose,
    handleCreateModalClose,
    isDeleteModalOpen,
    deleteActionList,
    deleteContent,
    error,
    handleCreateEditAction,
    initialValues,
    isEditModalOpen,
    fieldList,
    createEditHeaderContent,
    createEditBtnContent,
    breadCrumbContent,
    actionId,
    tableHeaderLabels
  }) =>
    isDataLoading ? (
      <Segment className={styles.root} loading={isDataLoading} size='massive' />
    ) : (
      <Segment size='massive'>
        <ContentHeader actionsList={contentHeaderActions} breadCrumbContent={breadCrumbContent} />

        <DataTable
          isLoading={isDataLoading}
          dataList={dataList}
          actionsList={tableActionsList}
          tableHeaderLabels={tableHeaderLabels}
        />

        <ModalMessage
          onModalClose={handleDeleteModalClose}
          actionsList={deleteActionList}
          open={isDeleteModalOpen}
          mainContent={deleteContent}
          errorContent={error}
          actionId={actionId}
          isLoading={isRequestLoading}
        />

        <ModalCreateEdit
          onModalClose={handleCreateModalClose}
          onSubmit={handleCreateEditAction}
          errorContent={error}
          initialValues={initialValues}
          open={isEditModalOpen}
          fieldList={fieldList}
          headerContent={createEditHeaderContent}
          saveBtnContent={createEditBtnContent}
          isLoading={isRequestLoading}
        />
      </Segment>
    )
);

TableView.defaultProps = {
  handleDeleteModalClose: () => {},
  deleteActionList: [],
  isDeleteModalOpen: false,
  deleteContent: '',
  actionId: null
};

TableView.propTypes = {
  tableHeaderLabels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string
    })
  ),
  actionId: PropTypes.number,
  breadCrumbContent: PropTypes.arrayOf(PropTypes.string),
  createEditBtnContent: PropTypes.string,
  createEditHeaderContent: PropTypes.string,
  fieldList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['number', 'string', 'array', 'bool', 'serverUsersSearch']),
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      ),
      loading: PropTypes.bool,
      link: PropTypes.bool
    })
  ),
  isEditModalOpen: PropTypes.bool,
  initialValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  handleCreateEditAction: PropTypes.func,
  error: PropTypes.string.isRequired,
  deleteContent: PropTypes.string,
  deleteActionList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
      action: PropTypes.func
    })
  ),
  isDeleteModalOpen: PropTypes.bool,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  contentHeaderActions: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
      action: PropTypes.func,
      floated: PropTypes.string
    })
  ),
  dataList: PropTypes.arrayOf(PropTypes.object),
  tableActionsList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      type: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  ),
  handleDeleteModalClose: PropTypes.func,
  handleCreateModalClose: PropTypes.func
};

export default TableView;
