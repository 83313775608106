import React from 'react';
import PropTypes from 'prop-types';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';

class Clients extends React.PureComponent {
  state = {
    id: null,
    isEditModalOpen: false,
    createEditBtnContent: '',
    createEditHeaderContent: '',
    initialValues: {},
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadClientData();
  }

  handleEditModalOpen = id => {
    this.setState({
      id: id,
      isEditModalOpen: true,
      initialValues: {
        ...this.props.loadSelectedClient(id)
      },
      createEditHeaderContent: 'Edit Client',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
  };

  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  handleSubmitUpdate = data => {
    const { loadClientToUpdate } = this.props;
    const { id } = this.state;
    const params = { data, id };

    loadClientToUpdate(params, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };

  handleSubmitNew = data => {
    const { loadCreateClient } = this.props;

    loadCreateClient(data, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };

  handleCreateModalOpen = () =>
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create new client',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });

  render () {
    const { isDataLoading, isRequestLoading, error, clientData } = this.props;
    const {
      isEditModalOpen,
      handleCreateEditAction,
      createEditBtnContent,
      createEditHeaderContent,
      initialValues
    } = this.state;

    const actionsList = [
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Client name',
        name: 'name',
        required: true
      }
    ];
    const contentHeaderActions = [
      {
        content: 'Create new client',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];

    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'date_created',
        label: 'Date Created'
      },
      {
        name: 'last_updated',
        label: 'Last Updated'
      },
      {
        name: 'name',
        label: 'Client Name'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['Clients']}
          handleCreateModalClose={this.handleCreateModalClose}
          tableActionsList={actionsList}
          dataList={clientData}
          contentHeaderActions={contentHeaderActions}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          error={error}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}
          isEditModalOpen={isEditModalOpen}
          fieldList={fieldList}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
        />
      </DashboardTemplate>
    );
  }
}

Clients.propTypes = {
  loadCreateClient: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  loadClientToUpdate: PropTypes.func.isRequired,
  loadSelectedClient: PropTypes.func.isRequired,
  loadClientData: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  clientData: PropTypes.array.isRequired
};

export default Clients;
