import React from 'react';
import { Button, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './MainMenu.module.scss';

export const MainMenu = ({ loadSubmitLogout, isLoading, isLogoutBtnVisible }) => {
  return (
    <section className={styles.root}>
      <Menu inverted>
        <Menu.Item position='left'>
          <Link to='/'>Orca admin panel</Link>
        </Menu.Item>
        <Menu.Item position='right'>
          {isLogoutBtnVisible && (
            <Button.Group vertical labeled icon>
              <Button
                icon='sign-out'
                primary
                content='Sign-out'
                onClick={() => loadSubmitLogout()}
                loading={isLoading}
              />
            </Button.Group>
          )}
        </Menu.Item>
      </Menu>
    </section>
  );
};

MainMenu.defaultProps = {
  isLogoutBtnVisible: true
};

MainMenu.propTypes = {
  loadSubmitLogout: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLogoutBtnVisible: PropTypes.bool
};

export default MainMenu;
