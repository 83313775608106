import ReportsService from 'services/ReportsService';
import * as actions from './actions';
import * as errorConstants from 'shared/constants/errorConstants';
import * as selectors from './selectors';

export const loadReportGroupId = id => {
  return dispatch => {
    dispatch(actions.setReportGroupId(id));
  };
};

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadSelectedReport = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedReport(getState(), id);
  };
};

export const loadReports = id => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    ReportsService.getReports(id)
      .then(response => {
        dispatch(loadResetError());
        dispatch(actions.setDataLoadingState(false));
        dispatch(actions.setReports(response.body));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadReportToDelete = (id, resolve) => {
  return (dispatch, getState) => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.deleteReport(id)
      .then(() => {
        // const groupId = selectors.getReportGroupId(getState());
        dispatch(actions.setRequestLoadingState(false));
        dispatch(actions.setDeleteReportData(id));
        resolve();
      })
      .catch(error => {
        console.error(error);
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCreateReport = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.postReport(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadReports(data.reportGroupId));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadReportToUpdate = ({ data, id }, resolve) => {
  return (dispatch, getState) => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.putReport(data, id)
      .then(() => {
        const groupId = selectors.getReportGroupId(getState());
        dispatch(actions.setRequestLoadingState(false));

        dispatch(loadReports(groupId));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};
