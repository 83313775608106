import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const ReportsService = {
  // GET
  getReportGroups: function () {
    return CallService.authorizedCallReports('GET', '/report_groups').then(jsonResponseHandler);
  },
  getReports: function (id) {
    return CallService.authorizedCallReports('GET', `/report_groups/${id}`).then(
      jsonResponseHandler
    );
  },

  // DELETE
  deleteReportGroup: function (id) {
    return CallService.authorizedCallReports('DELETE', `/report_groups/${id}`).then(
      jsonResponseHandler
    );
  },
  deleteReport: function (id) {
    return CallService.authorizedCallReports('DELETE', `/reports/${id}`).then(jsonResponseHandler);
  },

  // POST
  postReportGroup: function (data) {
    return CallService.authorizedCallReports('POST', '/report_groups', data).then(
      jsonResponseHandler
    );
  },
  postReport: function (data) {
    return CallService.authorizedCallReports('POST', '/reports', data).then(jsonResponseHandler);
  },

  // PUT
  putReportGroup: function (data, id) {
    return CallService.authorizedCallReports('PUT', `/report_groups/${id}`, data).then(
      jsonResponseHandler
    );
  },
  putReport: function (data, id) {
    return CallService.authorizedCallReports('PUT', `/reports/${id}`, data).then(
      jsonResponseHandler
    );
  }
};

export default ReportsService;
