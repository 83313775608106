import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.USEREMAILCONFIG_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.USEREMAILCONFIG_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.USEREMAILCONFIG_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.USEREMAILCONFIG_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setMarketList = configList => {
  return {
    type: types.USEREMAILCONFIG_SET_EMAIL_CONFIG_DATA,
    payload: { configList }
  };
};
