import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const UserEmailConfigurationService = {
  getAllUserEmailConfigurations: function () {
    return CallService.authorizedCall('GET', '/useremailconfigurations').then(jsonResponseHandler);
  },
  patchUserEmailConfigurations: function (data, id) {
    return CallService.authorizedCall('PATCH', `/useremailconfigurations/${id}`, data).then(
      jsonResponseHandler
    );
  },
  postUserEmailConfigurations: function (data) {
    return CallService.authorizedCall('POST', '/useremailconfigurations', data).then(
      jsonResponseHandler
    );
  }
};

export default UserEmailConfigurationService;
