import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const AuthService = {
  postLogin: function (userCredentials) {
    return CallService.unauthorizedCall('POST', '/users/login', userCredentials).then(
      jsonResponseHandler
    );
  },
  getOwnData: function () {
    return CallService.authorizedCall('GET', '/owndata').then(jsonResponseHandler);
  },
  postLogout: function () {
    return CallService.authorizedCall('POST', '/users/logout').then(jsonResponseHandler);
  },
  getTokenExpirationTime: function () {
    return CallService.authorizedCall('GET', '/users/tokenexpirationtime').then(
      jsonResponseHandler
    );
  },
  getRefreshToken: function () {
    return CallService.authorizedCall('GET', '/users/refreshtoken').then(jsonResponseHandler);
  }
};

export default AuthService;
