import { connect } from 'react-redux';
import Markets from '../components/Markets';
import {
  loadMarketList,
  loadSelectedMarket,
  loadResetError,
  loadMarketToUpdate,
  loadCreateMarket,
  loadClientList
} from '../redux/operations';

import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    marketList: selector.getMarketList(state),
    clientList: selector.getClientList(state)
  }),
  {
    loadClientList,
    loadCreateMarket,
    loadMarketToUpdate,
    loadResetError,
    loadSelectedMarket,
    loadMarketList
  }
)(Markets);
