import { find } from 'lodash';

export const getIsDataLoading = state => state.reportGroups.isDataLoading;

export const getIsRequestLoading = state => state.reportGroups.isRequestLoading;

export const getError = state => state.reportGroups.error;

export const getReportGroups = state => state.reportGroups.groups;

export const getSelectedGroup = (state, id) => find(state.reportGroups.groups, { id: id });

export const getReportGroupMarkets = state => state.reportGroups.markets;

export const getFilterString = state => state.reportGroups.filterString;
