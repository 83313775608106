import * as types from './types';

const ACTION_HANDLERS = {
  [types.MANTA_VIDEO_COMPONENTS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.MANTA_VIDEO_COMPONENTS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.MANTA_VIDEO_COMPONENTS_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.MANTA_VIDEO_COMPONENTS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.MANTA_VIDEO_COMPONENTS_SET_DATA]: (state, action) => ({
    ...state,
    videoComponentData: action.payload.videoComponentData
  }),
  [types.MANTA_VIDEO_COMPONENTS_SET_REMOVE_ITEM_FROM_STATE]: (state, action) => ({
    ...state,
    videoComponentData: state.videoComponentData.filter(item => item.id !== action.payload.id)
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  videoComponentData: [],
  error: ''
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
