import { connect } from 'react-redux';

import { loadSubmitLogout } from 'components/pages/Login/redux/operations';
import * as selector from 'components/pages/Login/redux/selectors';
import MainMenu from './MainMenu';

export default connect(
  state => ({
    isLoading: selector.getIsLoading(state),
    error: selector.getError(state)
  }),
  {
    loadSubmitLogout
  }
)(MainMenu);
