import React from 'react';
import { Input } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import AutoSave from '../AutoSave';

export const SearchField = React.memo(({ searchField }) => (
  <Form
    onSubmit={searchField.action}
    render={() => (
      <>
        <AutoSave debounceMs={500} update={searchField.action} />
        <Field
          name='search'
          render={({ input }) => (
            <Input
              icon='search'
              size='small'
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              placeholder={searchField.content}
            />
          )}
        />
      </>
    )}
  />
));

SearchField.propTypes = {
  searchField: PropTypes.shape({
    content: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  })
};

export default SearchField;
