import { connect } from 'react-redux';

import {
  loadResetError,
  loadVideoComponentData,
  loadSelectedVideoComponent,
  loadVideoComponentToUpdate,
  loadVideoComponentToDelete,
  loadVideoComponentToCreate
} from '../redux/operations';
import * as selector from '../redux/selectors';
import MantaVideoComponents from '../components/MantaVideoComponents';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    videoComponentData: selector.getVideoComponentData(state)
  }),
  {
    loadVideoComponentToCreate,
    loadVideoComponentToDelete,
    loadVideoComponentToUpdate,
    loadSelectedVideoComponent,
    loadVideoComponentData,
    loadResetError
  }
)(MantaVideoComponents);
