import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';
import MantaVideoComponentService from 'services/MantaVideoComponentService';

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadVideoComponentData = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    MantaVideoComponentService.getAllVideoComponents()
      .then(response => {
        dispatch(actions.setVideoComponentData(response.body));
      })
      .then(() => {
        dispatch(actions.setDataLoadingState(false));
        dispatch(loadResetError());
      })
      .catch(error => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
        throw error;
      });
  };
};

export const loadSelectedVideoComponent = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedVideoComponent(getState(), id);
  };
};

export const loadVideoComponentToUpdate = ({ data, id }, resolve) => {
  const prepData = {
    description: data.description,
    creative_component_id: id,
    is_creative: !!data.is_creative,
    body: data.body,
    name: data.name
  };
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));
    MantaVideoComponentService.updateVideoComponent(prepData)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadVideoComponentData());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadVideoComponentToCreate = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));
    const prepData = {
      name: data.name,
      description: data.description,
      is_creative: !!data.is_creative,
      initial_creative_component_instance: {
        body: data.body
      }
    };
    MantaVideoComponentService.postVideoComponent(prepData)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadVideoComponentData());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadVideoComponentToDelete = (id, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    MantaVideoComponentService.deleteVideoComponent(id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(actions.setDeleteVideoComponentData(id));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};
