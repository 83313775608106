import * as types from './types';

const ACTION_HANDLERS = {
  [types.CLIENTS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.CLIENTS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.CLIENTS_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.CLIENTS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.CLIENTS_SET_CLIENT_DATA]: (state, action) => ({
    ...state,
    clientData: action.payload.clientData
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  isLoading: true,
  clientData: [],
  error: ''
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
