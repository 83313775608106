import React, { useState } from 'react';
import CallService from 'services/CallService';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { jsonResponseHandler } from 'services/utils';

const DEBOUNCE_TIME = 400; // ms

const SearchableUsersDropdown = ({ onChange, name }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooShortSearch, setShowTooShortSearch] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);

  const handleSearchChange = debounce(async (event, { searchQuery }) => {
    setOptions([]);
    if (searchQuery.length < 3) {
      setShowTooShortSearch(true);
      setShowNoResults(false);

      return;
    }
    setShowTooShortSearch(false);

    setIsLoading(true);
    CallService.authorizedCall('GET', '/users', null, {
      fields: 'id,email,first_name,last_name',
      textsearch: searchQuery
    })
      .then(jsonResponseHandler)
      .then(({ body: userResponse }) => {
        const parsedUsers = [];

        userResponse.forEach(({ id, email, first_name, last_name }) =>
          parsedUsers.push({
            key: id,
            text: `${first_name} ${last_name} (${email}) - (ID: ${id})`,
            value: id
          })
        );
        setOptions(parsedUsers);
        setShowNoResults(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, DEBOUNCE_TIME);

  return (
    <div style={{ position: 'relative' }}>
      <Dropdown
        fluid
        search
        selection
        options={options}
        loading={isLoading}
        name={name}
        onChange={onChange}
        placeholder='Type to search'
        onSearchChange={handleSearchChange}
        noResultsMessage={showNoResults ? 'No users found.' : null}
      />
      {showTooShortSearch && (
        <div style={{ position: 'absolute', top: '43px', left: '2px' }}>
          <p style={{ color: 'red', fontSize: '12px' }}>Type in min. 3 characters</p>
        </div>
      )}
    </div>
  );
};

SearchableUsersDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default SearchableUsersDropdown;
