import { find } from 'lodash';

export const getIsDataLoading = state => state.mantaVideoComponents.isDataLoading;

export const getIsRequestLoading = state => state.mantaVideoComponents.isRequestLoading;

export const getError = state => state.mantaVideoComponents.error;

export const getVideoComponentData = state => state.mantaVideoComponents.videoComponentData;

export const getSelectedVideoComponent = (state, id) =>
  find(state.mantaVideoComponents.videoComponentData, { id: id });
