import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const ClientsService = {
  getAllClients: function () {
    return CallService.authorizedCall('GET', '/clients').then(jsonResponseHandler);
  },
  patchClient: function (data, id) {
    return CallService.authorizedCall('PATCH', `/clients/${id}`, data).then(jsonResponseHandler);
  },
  postClient: function (data) {
    return CallService.authorizedCall('POST', '/clients', data).then(jsonResponseHandler);
  }
};

export default ClientsService;
