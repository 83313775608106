// REPORT GROUPS
export const WARNING_DELETE_REPORT = 'Are you sure you want to delete selected report?';
export const WARNING_DELETE_GROUP = 'Are you sure you want to delete selected group?';
export const WARNING_DELETE_CLIENT = 'Are you sure you want to delete selected client?';
export const WARNING_DELETE_COMPONENT =
  'Are you sure you want to delete selected component instance?';

// GENERAL MESSAGES
export const WARNING_OFFLINE_MODE =
  'You are currently offline. Please check your network connection and reload the page';

// USER APPLICATIONS
export const WARNING_DELETE_USERAPPLICATION_ASSOCIATION =
  'Are you sure you want to delete selected user application association?';
