import React from 'react';
import { Form as SemanticForm, Grid, Header, Segment, Message } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Detector } from 'react-detect-offline';

import WarningOfflineMessage from 'components/molecules/WarningOfflineMessage';
import CTA from 'components/molecules/CTA';
import * as errorConstants from 'shared/constants/errorConstants';

import styles from './LoginForm.module.scss';

export const LoginForm = ({ onLoadSubmitedLoginData, error, isLoading }) => {
  const handleSubmit = values => onLoadSubmitedLoginData(values);

  const handleValidation = values => {
    const errors = {};

    if (!values.email) {
      errors.email = errorConstants.ERROR_LOGIN_NO_EMAIL_FILLED;
    }
    if (!values.password) {
      errors.password = errorConstants.ERROR_LOGIN_NO_PASSWORD_FILLED;
    }

    return errors;
  };

  const hasNoErrorMessage = () => error === '';

  const renderLoginForm = online => (
    <div className={styles.loginForm}>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h1' className={styles.companyName} textAlign='center'>
            ORCA ADMIN PANEL
          </Header>

          <Form
            onSubmit={handleSubmit}
            validate={handleValidation}
            render={({ handleSubmit, pristine, submitting }) => (
              <SemanticForm size='large' onSubmit={handleSubmit}>
                <Segment stacked attached>
                  <Field
                    name='email'
                    render={({ input, meta }) => (
                      <>
                        <SemanticForm.Input
                          error={meta.error && meta.touched}
                          meta={meta.error}
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='E-mail address'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && <Message negative content={meta.error} />}
                      </>
                    )}
                  />

                  <Field
                    name='password'
                    render={({ input, meta }) => (
                      <>
                        <SemanticForm.Input
                          error={meta.error && meta.touched}
                          meta={meta.error}
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='Password'
                          type='password'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                        />

                        {meta.error && meta.touched && <Message negative content={meta.error} />}
                      </>
                    )}
                  />
                  <CTA
                    onClick={handleSubmit}
                    disabled={pristine || submitting}
                    content='LOGIN'
                    isLoading={isLoading}
                  />

                  <Message negative hidden={hasNoErrorMessage()} header={error} size='small' />
                </Segment>
              </SemanticForm>
            )}
          />
          {!online && <WarningOfflineMessage attached />}
        </Grid.Column>
      </Grid>
    </div>
  );

  return (
    <div className={styles.root}>
      <Detector render={({ online }) => renderLoginForm(online)} />
    </div>
  );
};

LoginForm.propTypes = {
  onLoadSubmitedLoginData: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool
};

export default LoginForm;
