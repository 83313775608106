import React from 'react';
import { Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Breadcrumbs from 'components/molecules/Breadcrumbs';
import CTA from 'components/molecules/CTA';
import SearchField from 'components/molecules/SearchField';
import styles from './ContentHeader.module.scss';

const ContentHeader = React.memo(
  ({ actionsList = [], location, breadCrumbContent, searchField = [] }) => {
    const getFilterFields = () => {
      if (isEmpty(searchField)) {
        return null;
      }

      return <SearchField searchField={searchField} />;
    };

    const getButtons = () => {
      if (isEmpty(actionsList)) {
        return null;
      }

      return actionsList.map((item, index) => (
        <CTA key={`header-button-${index}`} {...item} onClick={() => item.action()} />
      ));
    };

    return (
      <div className={styles.root}>
        <Grid>
          <Grid.Column floated='left' width={12}>
            {breadCrumbContent && (
              <Breadcrumbs path={location.pathname} breadCrumbContent={breadCrumbContent} />
            )}
          </Grid.Column>

          <Grid.Column floated='right' width={4}>
            <div>
              {getFilterFields()}
              {getButtons()}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
);

ContentHeader.propTypes = {
  breadCrumbContent: PropTypes.arrayOf(PropTypes.string),
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
      action: PropTypes.func
    })
  ),
  searchField: PropTypes.shape({
    content: PropTypes.string,
    action: PropTypes.func
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default withRouter(ContentHeader);
