export const USERAPPLICATIONS_SET_LOADING_STATE = 'USERAPPLICATIONS_SET_LOADING_STATE';
export const USERAPPLICATIONS_SET_DATA_LOADING_STATE = 'USERAPPLICATIONS_SET_DATA_LOADING_STATE';
export const USERAPPLICATIONS_SET_REQUEST_LOADING_STATE =
  'USERAPPLICATIONS_SET_REQUEST_LOADING_STATE';
export const USERAPPLICATIONS_SET_ERROR = 'USERAPPLICATIONS_SET_ERROR';
export const USERAPPLICATIONS_RESET_ERROR = 'USERAPPLICATIONS_RESET_ERROR';
export const USERAPPLICATIONS_SET_APPLICATION_DATA = 'USERAPPLICATIONS_SET_APPLICATION_DATA';
export const USERAPPLICATIONS_SET_REMOVE_ITEM_FROM_STATE =
  'USERAPPLICATIONS_SET_REMOVE_ITEM_FROM_STATE';
export const USERAPPLICATIONS_SET_AVAILABLE_APPLICATION_DATA =
  `USERAPPLICATIONS_SET_AVAILABLE_APPLICATION_DATA`;
export const USERAPPLICATIONS_SET_AVAILABLE_USER_DATA =
  ``;
