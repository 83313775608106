import * as types from './types';

export const setLoadingState = isLoading => ({
  type: types.LOGIN_SET_LOADING_STATE,
  payload: { isLoading }
});

export const setUserId = userId => ({
  type: types.LOGIN_SET_USER_ID,
  payload: { userId }
});

export const setError = error => ({
  type: types.LOGIN_SET_ERROR,
  payload: { error }
});

export const resetError = () => ({
  type: types.LOGIN_RESET_ERROR,
  payload: { error: '' }
});

export const resetUserData = () => ({
  type: types.LOGIN_RESET_USER_DATA,
  payload: {
    userId: ''
  }
});
