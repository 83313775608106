import { get } from 'lodash';
import UserApplicationsService from 'services/UserApplicationsService';
import ApplicationsService from 'services/ApplicationsService';
import UsersService from 'services/UsersService';
import * as actions from './actions';
import * as errorConstants from 'shared/constants/errorConstants';

export const loadAvailableUsers = () => {
  return dispatch => {
    UsersService.getAllUsers()
      .then(({ body: userResponse }) => {
        const parsedUsers = [];

        userResponse.forEach(item =>
          parsedUsers.push({
            key: item.id,
            text: `${item.email} - (ID: ${item.id})`,
            value: item.id
          })
        );
        dispatch(actions.setAvailableUserData(parsedUsers));
        dispatch(loadResetError());
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadAvailableApplications = () => {
  return dispatch => {
    ApplicationsService.getAllApplications()
      .then(({ body: applicationResponse }) => {
        const parsedApplications = [];

        applicationResponse.forEach(item =>
          parsedApplications.push({
            key: item.id,
            text: `${item.name} - (ID: ${item.id})`,
            value: item.id
          })
        );
        dispatch(actions.setAvailableApplicationData(parsedApplications));
        dispatch(loadResetError());
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadUserApplicationList = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    UserApplicationsService.getAllUserApplications()
      .then(({ body: userAppResponse }) => {
        let parsedUserApps = [];

        if (userAppResponse) {
          userAppResponse.forEach(item =>
            parsedUserApps.push({
              id: item.id,
              user_id: get(item, 'user.id'),
              user_email: get(item, 'user.email'),
              app_id: get(item, 'application.id'),
              app_name: get(item, 'application.name')
            })
          );
        }

        dispatch(actions.setUserApplicationList(parsedUserApps));
        dispatch(loadResetError());
        dispatch(actions.setDataLoadingState(false));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadUserApplicationAssociationToDelete = (id, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    UserApplicationsService.deleteUserApplicationsById(id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(actions.setDeleteUserApplicationAssociationData(id));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCreateApplicationAssociation = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    UserApplicationsService.postUserApplications(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadUserApplicationList());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};
