import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.COMPANIES_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.COMPANIES_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.COMPANIES_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.COMPANIES_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setCompaniesList = companiesList => {
  return {
    type: types.COMPANIES_SET_COMPANY_DATA,
    payload: { companiesList }
  };
};
