import * as types from './types';

const ACTION_HANDLERS = {
  [types.MARKETS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.MARKETS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.MARKETS_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.MARKETS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.MARKETS_SET_MARKET_DATA]: (state, action) => ({
    ...state,
    marketList: action.payload.marketList
  }),
  [types.MARKETS_SET_CLIENT_LIST]: (state, action) => ({
    ...state,
    clientList: action.payload.clientList
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  marketList: [],
  clientList: []
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
