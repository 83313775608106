import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { times, find } from 'lodash';

export const DataTableHeaderRow = React.memo(({ contentList, actionsCount, tableHeaderLabels }) => {
  const getHeaderLabel = item => {
    const labelObject = find(tableHeaderLabels, { name: item });

    return labelObject ? labelObject.label : item;
  };

  return (
    <Table.Row>
      {contentList.map(item => (
        <Table.HeaderCell key={`header-item-${item}`}>{getHeaderLabel(item)}</Table.HeaderCell>
      ))}
      {times(actionsCount, (count = 0) => {
        count++;
        return <Table.HeaderCell key={`header-item-${count}`} />;
      })}
    </Table.Row>
  );
});

DataTableHeaderRow.propTypes = {
  tableHeaderLabels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string
    })
  ),
  contentList: PropTypes.arrayOf(PropTypes.string),
  actionsCount: PropTypes.number
};

export default DataTableHeaderRow;
