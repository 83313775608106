import React from 'react';
import PropTypes from 'prop-types';
import { omit, isEmpty } from 'lodash';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';

class Markets extends React.PureComponent {
  state = {
    isEditModalOpen: false,
    id: null,
    initialValues: {},
    createEditHeaderContent: '',
    createEditBtnContent: '',
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadMarketList();
  }

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };
  handleEditModalOpen = async id => {
    const selectedData = omit(this.props.loadSelectedMarket(id), 'id');
    this.setState({
      id: id,
      isEditModalOpen: true,
      initialValues: {
        ...selectedData
      },
      createEditHeaderContent: 'Edit Market',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
    await this.props.loadClientList();
  };
  handleCreateModalOpen = () =>
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create new market',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  handleSubmitNew = data => {
    const { loadCreateMarket } = this.props;

    loadCreateMarket(data, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };
  handleSubmitUpdate = data => {
    const { loadMarketToUpdate } = this.props;
    const { id } = this.state;
    const params = { data, id };

    loadMarketToUpdate(params, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };

  render () {
    const {
      isEditModalOpen,
      initialValues,
      createEditHeaderContent,
      createEditBtnContent,
      handleCreateEditAction
    } = this.state;
    const { isDataLoading, isRequestLoading, marketList, error, clientList } = this.props;
    const contentHeaderActions = [
      {
        content: 'Create new market',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];
    const actionsList = [
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Market name',
        name: 'name',
        required: true
      },
      {
        type: 'string',
        label: 'Market code name',
        name: 'code_name',
        required: true
      },
      {
        type: 'string',
        label: 'Market country code',
        name: 'country_code',
        required: true
      },
      {
        type: 'bool',
        label: 'RDAM enabled',
        name: 'rdam_enabled',
        required: false
      },
      {
        type: 'array',
        label: 'Client',
        name: 'client_id',
        required: false,
        options: clientList,
        loading: isEmpty(clientList)
      }
    ];

    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'client_id',
        label: 'Client ID'
      },
      {
        name: 'code_name',
        label: 'Code Name'
      },
      {
        name: 'country_code',
        label: 'Country Code'
      },
      {
        name: 'name',
        label: 'Market Name'
      },
      {
        name: 'rdam_enabled',
        label: 'RDAM Enabled'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['Markets']}
          handleCreateModalClose={this.handleCreateModalClose}
          handleDeleteModalClose={this.handleDeleteModalClose}
          tableActionsList={actionsList}
          dataList={marketList}
          contentHeaderActions={contentHeaderActions}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          error={error}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}
          isEditModalOpen={isEditModalOpen}
          fieldList={fieldList}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
        />
      </DashboardTemplate>
    );
  }
}

Markets.propTypes = {
  loadClientList: PropTypes.func.isRequired,
  loadCreateMarket: PropTypes.func.isRequired,
  loadMarketToUpdate: PropTypes.func.isRequired,
  loadSelectedMarket: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  loadMarketList: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  marketList: PropTypes.array.isRequired,
  clientList: PropTypes.array.isRequired
};

export default Markets;
