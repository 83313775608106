import { connect } from 'react-redux';
import { loadSubmitedLoginData } from '../redux/operations';
import * as selector from '../redux/selectors';
import Login from '../components/Login';

export default connect(
  state => ({
    isLoading: selector.getIsLoading(state),
    error: selector.getError(state)
  }),
  {
    loadSubmitedLoginData
  }
)(Login);
