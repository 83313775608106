import React from 'react';
import PropTypes from 'prop-types';

import LoginTemplate from 'components/templates/LoginTemplate';
import LoginForm from 'components/organisms/LoginForm';

export const Login = React.memo(({ loadSubmitedLoginData, error, isLoading }) => {
  return (
    <LoginTemplate>
      <LoginForm
        onLoadSubmitedLoginData={loadSubmitedLoginData}
        error={error}
        isLoading={isLoading}
      />
    </LoginTemplate>
  );
});

Login.propTypes = {
  loadSubmitedLoginData: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
};

export default Login;
