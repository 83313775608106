import { find } from 'lodash';

export const getIsDataLoading = state => state.reports.isDataLoading;

export const getIsRequestLoading = state => state.reports.isRequestLoading;

export const getError = state => state.reports.error;

export const getReports = state => state.reports.reportData;

export const getReportGroupId = state => state.reports.groupId;

export const getSelectedReport = (state, id) => find(state.reports.reportData.reports, { id: id });
