import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, loadTokenExpirationTime, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      localStorage.getItem('jwttoken') ? <Component {...props} /> : <Redirect to='/login' />
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  loadTokenExpirationTime: PropTypes.func
};

export default PrivateRoute;
