import { combineReducers } from 'redux';
import loginReducer from 'components/pages/Login/redux/reducer';
import reportGroupsReducer from 'components/pages/ReportGroups/redux/reducer';
import reportsReducer from 'components/pages/Reports/redux/reducer';
import clientsReducer from 'components/pages/Clients/redux/reducer';
import marketsReducer from 'components/pages/Markets/redux/reducer';
import configListReducer from 'components/pages/UserEmailConfigurations/redux/reducer';
import companiesReducer from 'components/pages/Companies/redux/reducer';
import mantaVideoComponentReducer from 'components/pages/MantaVideoComponents/redux/reducer';
import userApplicationsReducer from 'components/pages/UserApplications/redux/reducer';

// Add page reducers here:
const allReducers = combineReducers({
  login: loginReducer,
  reportGroups: reportGroupsReducer,
  reports: reportsReducer,
  clients: clientsReducer,
  markets: marketsReducer,
  useremailconfigurations: configListReducer,
  companies: companiesReducer,
  mantaVideoComponents: mantaVideoComponentReducer,
  userApplications: userApplicationsReducer
});

export default allReducers;
