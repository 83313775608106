import { find } from 'lodash';

export const getIsDataLoading = state => state.markets.isDataLoading;

export const getIsRequestLoading = state => state.markets.isRequestLoading;

export const getError = state => state.markets.error;

export const getMarketList = state => state.markets.marketList;

export const getClientList = state => state.markets.clientList;

export const getSelectedMarketList = (state, id) => find(state.markets.marketList, { id: id });
