import { connect } from 'react-redux';
import ReportGroups from '../components/ReportGroups';
import {
  loadReportGroups,
  loadReportGroupToDelete,
  loadCreateGroup,
  loadSelectedGroup,
  loadReportGroupToUpdate,
  loadResetError,
  loadMarkets,
  loadFilterString
} from '../redux/operations';

import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    groups: selector.getReportGroups(state),
    markets: selector.getReportGroupMarkets(state),
    filterString: selector.getFilterString(state)
  }),
  {
    loadFilterString,
    loadMarkets,
    loadResetError,
    loadReportGroupToUpdate,
    loadReportGroups,
    loadReportGroupToDelete,
    loadCreateGroup,
    loadSelectedGroup
  }
)(ReportGroups);
