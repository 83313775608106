import * as types from './types';

const ACTION_HANDLERS = {
  [types.USERAPPLICATIONS_SET_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.payload.isLoading
  }),
  [types.USERAPPLICATIONS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.USERAPPLICATIONS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.USERAPPLICATIONS_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.USERAPPLICATIONS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.USERAPPLICATIONS_SET_APPLICATION_DATA]: (state, action) => ({
    ...state,
    userApplicationList: action.payload.userApplicationList
  }),
  [types.USERAPPLICATIONS_SET_REMOVE_ITEM_FROM_STATE]: (state, action) => ({
    ...state,
    userApplicationList: state.userApplicationList.filter(item => item.id !== action.payload.id)
  }),
  [types.USERAPPLICATIONS_SET_AVAILABLE_APPLICATION_DATA]: (state, action) => ({
    ...state,
    availableApplications: action.payload.availableApplications
  }),
  [types.USERAPPLICATIONS_SET_AVAILABLE_USER_DATA]: (state, action) => ({
    ...state,
    availableUsers: action.payload.availableUsers
  })
};

const initialState = {
  isLoading: true,
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  userApplicationList: [],
  availableApplications: [],
  availableUsers: []
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
