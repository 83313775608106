import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.REPORT_GROUPS_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.REPORT_GROUPS_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.REPORT_GROUPS_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.REPORT_GROUPS_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setReportGroups = groups => {
  return {
    type: types.REPORT_GROUPS_SET_GROUPS,
    payload: { groups }
  };
};

export const setReportGroupMarkets = markets => {
  return {
    type: types.REPORT_GROUPS_SET_MARKETS,
    payload: { markets }
  };
};

export const setFilterString = filterString => {
  return {
    type: types.REPORT_GROUPS_SET_FILTER_STRING,
    payload: { filterString }
  };
};

export const setDeleteReportGroupData = id => {
  return {
    type: types.REPORT_GROUPS_SET_REMOVE_ITEM_FROM_STATE,
    payload: { id }
  };
};
