/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { debounce } from 'lodash';

class AutoSave extends React.PureComponent {
  constructor (props) {
    super(props);
    this._promise = null;
    this._debouncedSave = debounce(() => this.save(), this.props.debounceMs);
  }

  componentDidUpdate () {
    this._debouncedSave();
  }

  componentWillUnmount () {
    this._debouncedSave.flush();
  }

  async save () {
    if (this._promise) {
      await this._promise;
    }

    const { values, update } = this.props;

    this._promise = update(values);
    await this._promise;
    delete this._promise;
  }

  render () {
    return null;
  }
}

AutoSave.propTypes = {
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  update: PropTypes.func.isRequired,
  debounceMs: PropTypes.number.isRequired
};

export default props => <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />;
