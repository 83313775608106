import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'components/atoms/ErrorBoundary';

class TokenDetector extends React.PureComponent {
  componentDidMount () {
    this.props.loadTokenExpirationTime();
    this.props.reloadUserData();

    window.addEventListener('storage', this.localStorageUpdated);
  }

  componentWillUnmount () {
    window.removeEventListener('storage', this.localStorageUpdated);
  }

  localStorageUpdated () {
    if (!localStorage.getItem('jwttoken')) {
      window.location.replace('/login');
    }
  }

  render () {
    return <ErrorBoundary>{this.props.children}</ErrorBoundary>;
  }
}

TokenDetector.propTypes = {
  children: PropTypes.node,
  reloadUserData: PropTypes.func.isRequired,
  loadTokenExpirationTime: PropTypes.func.isRequired
};

export default TokenDetector;
