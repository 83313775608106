import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const ApplicationsService = {
  getAllApplications: function () {
    return CallService.authorizedCall('GET', '/applications').then(jsonResponseHandler);
  },
  getApplicationsById: function (id) {
    return CallService.authorizedCall('GET', `/applications/${id}`).then(jsonResponseHandler);
  }
};

export default ApplicationsService;
