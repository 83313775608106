import { connect } from 'react-redux';
import UserApplications from '../components/UserApplications';
import {
  loadResetError,
  loadUserApplicationList,
  loadUserApplicationAssociationToDelete,
  loadAvailableApplications,
  loadAvailableUsers,
  loadCreateApplicationAssociation
} from '../redux/operations';

import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    userApplicationList: selector.getUserApplicationList(state),
    availableApplications: selector.getAvailableApplicationList(state),
    availableUsers: selector.getAvailableUserList(state)
  }),
  {
    loadCreateApplicationAssociation,
    loadAvailableUsers,
    loadAvailableApplications,
    loadUserApplicationAssociationToDelete,
    loadResetError,
    loadUserApplicationList
  }
)(UserApplications);
