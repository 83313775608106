import { connect } from 'react-redux';
import Companies from '../components/Companies';
import {
  loadCreateCompaniesList,
  loadCompaniesListToUpdate,
  loadCompaniesList,
  loadResetError,
  loadSelectedCompaniesList
} from '../redux/operations';

import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    companiesList: selector.getCompaniesList(state)
  }),
  {
    loadSelectedCompaniesList,
    loadResetError,
    loadCompaniesList,
    loadCompaniesListToUpdate,
    loadCreateCompaniesList
  }
)(Companies);
