import { find } from 'lodash';

export const getIsDataLoading = state => state.useremailconfigurations.isDataLoading;

export const getIsRequestLoading = state => state.useremailconfigurations.isRequestLoading;

export const getError = state => state.useremailconfigurations.error;

export const getConfigList = state => state.useremailconfigurations.configList;

export const getSelectedConfigList = (state, id) =>
  find(state.useremailconfigurations.configList, { id: id });
