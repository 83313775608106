import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import TokenDetector from 'components/molecules/TokenDetector';
import MainMenu from 'components/organisms/MainMenu';
import SideMenu from 'components/organisms/SideMenu';
import ErrorBoundary from 'components/atoms/ErrorBoundary';
import ModalOfflineWarning from 'components/organisms/ModalOfflineWarning';

import styles from './DashboardTemplate.module.scss';

export const DashboardTemplate = ({ children }) => (
  <div className={styles.root}>
    <TokenDetector>
      <MainMenu />

      <Grid stretched padded columns='equal' className={styles.content}>
        <SideMenu />

        <div className={styles.componentWrapper}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </Grid>

      <ModalOfflineWarning />
    </TokenDetector>
  </div>
);

DashboardTemplate.propTypes = {
  children: PropTypes.node
};

export default DashboardTemplate;
