import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';
import ClientsService from 'services/ClientsService';

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadClientData = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    ClientsService.getAllClients()
      .then(response => {
        dispatch(actions.setClientData(response.body));
        dispatch(actions.setDataLoadingState(false));
        dispatch(loadResetError());
      })
      .catch(error => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
        throw error;
      });
  };
};

export const loadSelectedClient = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedClient(getState(), id);
  };
};

export const loadClientToUpdate = ({ data, id }, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ClientsService.patchClient(data, id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadClientData());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCreateClient = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ClientsService.postClient(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadClientData());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};
