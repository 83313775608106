import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';
import * as warningConstants from 'shared/constants/warningConstants';

class Markets extends React.PureComponent {
  state = {
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    id: null,
    initialValues: {},
    createEditHeaderContent: '',
    createEditBtnContent: '',
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadUserApplicationList();
  };

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };

  handleCreateModalOpen = async () => {
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create new application association',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });
    await this.props.loadAvailableApplications();
  };
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };

  handleDeleteModalOpen = id =>
    this.setState({
      isDeleteModalOpen: true,
      id: id
    });

    handleSubmitNew = data => {
      const { loadCreateApplicationAssociation } = this.props;

      loadCreateApplicationAssociation(data, () => {
        if (!this.isErrorDefined()) {
          this.handleCreateModalClose();
        }
      });
    };

  handleDeleteModalClose = () => {
    this.resetModalState();
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDeleteSubmit = () => {
    const { loadUserApplicationAssociationToDelete } = this.props;
    const { id } = this.state;

    loadUserApplicationAssociationToDelete(id, () => {
      if (!this.isErrorDefined()) {
        this.handleDeleteModalClose();
      }
    });
  };

  render () {
    const {
      isDeleteModalOpen,
      isEditModalOpen,
      createEditHeaderContent,
      createEditBtnContent,
      initialValues,
      handleCreateEditAction
    } = this.state;
    const {
      isDataLoading,
      isRequestLoading,
      userApplicationList,
      error,
      availableApplications
    } = this.props;

    const contentHeaderActions = [
      {
        content: 'Create new user application association',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];

    const actionsList = [
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteModalOpen
      }
    ];

    const deleteActionList = [
      {
        content: 'Cancel',
        type: 'standard',
        action: this.handleDeleteModalClose
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteSubmit
      }
    ];

    const fieldList = [
      {
        type: 'array',
        label: 'Application',
        name: 'application_id',
        required: true,
        options: availableApplications,
        loading: isEmpty(availableApplications)
      },
      {
        type: 'serverUsersSearch',
        label: 'User',
        name: 'user_id',
        required: true
      }
    ];

    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'app_id',
        label: 'Application ID'
      },
      {
        name: 'app_name',
        label: 'Application name'
      },
      {
        name: 'user_email',
        label: 'User email'
      },
      {
        name: 'user_id',
        label: 'User ID'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          isEditModalOpen={isEditModalOpen}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
          fieldList={fieldList}
          handleCreateModalClose={this.handleCreateModalClose}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}

          handleDeleteModalClose={this.handleDeleteModalClose}
          deleteActionList={deleteActionList}
          isDeleteModalOpen={isDeleteModalOpen}
          deleteContent={warningConstants.WARNING_DELETE_USERAPPLICATION_ASSOCIATION}

          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['User Applications']}
          contentHeaderActions={contentHeaderActions}

          tableActionsList={actionsList}
          dataList={userApplicationList}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          error={error}
        />
      </DashboardTemplate>
    );
  }
}

Markets.propTypes = {
  loadCreateApplicationAssociation: PropTypes.func.isRequired,
  availableApplications: PropTypes.array,
  loadAvailableApplications: PropTypes.func.isRequired,
  loadUserApplicationAssociationToDelete: PropTypes.func.isRequired,
  loadUserApplicationList: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  userApplicationList: PropTypes.array.isRequired
};

export default Markets;
