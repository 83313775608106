import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { drop } from 'lodash';
import { Breadcrumb, Header } from 'semantic-ui-react';

export const Breadcrumbs = React.memo(({ path, breadCrumbContent }) => {
  const getBreadcrumbs = () => {
    const breadcrumbData = [];
    const splittedPath = drop(path.split('/'));

    const rowLen = splittedPath.length;

    splittedPath.forEach((item, index) => {
      breadcrumbData.push({
        link: index > 0 ? `/${splittedPath[index - 1]}/${item}` : `/${item}`,
        text: breadCrumbContent[index],
        isFirst: index === 0,
        isLast: rowLen === index + 1
      });
    });

    return breadcrumbData.map((item, index) => (
      <span key={`breadcrum-${index}`}>
        <Breadcrumb.Section active={item.isLast}>
          <Header as='h2'>
            <Link to={item.link}>{item.text}</Link>
          </Header>
        </Breadcrumb.Section>
        {!item.isLast && <Breadcrumb.Divider icon='right chevron' />}
      </span>
    ));
  };

  return <Breadcrumb size='massive'>{getBreadcrumbs()}</Breadcrumb>;
});

Breadcrumbs.propTypes = {
  breadCrumbContent: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.string
};

export default Breadcrumbs;
