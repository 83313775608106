import { connect } from 'react-redux';
import TokenDetector from './TokenDetector';
import {
  loadTokenExpirationTime,
  loadSubmitLogout,
  reloadUserData
} from 'components/pages/Login/redux/operations';
import * as selector from 'components/pages/Login/redux/selectors';

export default connect(
  state => ({
    isLoading: selector.getIsLoading(state),
    error: selector.getError(state)
  }),
  {
    reloadUserData,
    loadSubmitLogout,
    loadTokenExpirationTime
  }
)(TokenDetector);
