// import { filter } from 'lodash';
import ReportsService from 'services/ReportsService';
import MarketsService from 'services/MarketsService';
import * as actions from './actions';
import * as errorConstants from 'shared/constants/errorConstants';
import * as selectors from './selectors';

export const loadMarkets = () => {
  return dispatch => {
    MarketsService.getAllMarkets()
      .then(response => {
        const marketResponse = response.body;
        const parsedMarkets = [];

        marketResponse.forEach(item =>
          parsedMarkets.push({
            key: item.id,
            text: `${item.name} - ${item.code_name}`,
            value: item.id
          })
        );
        dispatch(actions.setReportGroupMarkets(parsedMarkets));
        dispatch(loadResetError());
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadReportGroups = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    ReportsService.getReportGroups()
      .then(response => {
        dispatch(actions.resetError());
        dispatch(actions.setDataLoadingState(false));
        dispatch(actions.setReportGroups(response.body));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadSelectedGroup = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedGroup(getState(), id);
  };
};

export const loadReportGroupToDelete = (id, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.deleteReportGroup(id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(actions.setDeleteReportGroupData(id));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCreateGroup = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.postReportGroup(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadReportGroups());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadReportGroupToUpdate = ({ data, id }, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    ReportsService.putReportGroup(data, id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadReportGroups());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadFilterString = (filterString = '') => {
  return dispatch => {
    dispatch(actions.setFilterString(filterString));
  };
};
