import React from 'react';
import PropTypes from 'prop-types';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';
import * as warningConstants from 'shared/constants/warningConstants';

class MantaVideoComponents extends React.PureComponent {
  state = {
    id: null,
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    createEditBtnContent: '',
    createEditHeaderContent: '',
    initialValues: {},
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadVideoComponentData();
  }

  handleEditModalOpen = id => {
    this.setState({
      id: id,
      isEditModalOpen: true,
      initialValues: {
        ...this.props.loadSelectedVideoComponent(id)
      },
      createEditHeaderContent: 'Edit Manta Video Component',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
  };
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };
  handleDeleteModalOpen = id =>
    this.setState({
      isDeleteModalOpen: true,
      id: id
    });
  handleDeleteModalClose = () => {
    this.resetModalState();
    this.setState({
      isDeleteModalOpen: false
    });
  };
  handleDeleteSubmit = () => {
    const { error, loadVideoComponentToDelete } = this.props;
    const { id } = this.state;

    loadVideoComponentToDelete(id, () => {
      if (!error) {
        this.handleDeleteModalClose();
      }
    });
  };
  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  // Used for update and create
  handleSubmitUpdate = data => {
    const { loadVideoComponentToUpdate } = this.props;
    const { id } = this.state;
    const params = { data, id };

    loadVideoComponentToUpdate(params, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };
  handleSubmitCreate = data => {
    const { loadVideoComponentToCreate } = this.props;

    loadVideoComponentToCreate(data, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };
  handleCreateModalOpen = () =>
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create New Video Component',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitCreate
    });

  render () {
    const { isDataLoading, isRequestLoading, error, videoComponentData } = this.props;
    const {
      isEditModalOpen,
      isDeleteModalOpen,
      handleCreateEditAction,
      createEditBtnContent,
      createEditHeaderContent,
      initialValues
    } = this.state;

    const actionsList = [
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteModalOpen
      }
    ];
    const contentHeaderActions = [
      {
        content: 'Create New Video Component',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];
    const deleteActionList = [
      {
        content: 'Cancel',
        type: 'standard',
        action: this.handleDeleteModalClose
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteSubmit
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Component Name',
        name: 'name',
        required: true
      },
      {
        type: 'string',
        label: 'Description',
        name: 'description',
        required: true
      },
      {
        type: 'bool',
        label: 'Is Creative',
        name: 'is_creative',
        required: false
      },
      {
        type: 'string',
        label: 'Base64 Body',
        name: 'body',
        required: true
      }
    ];
    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'date_created',
        label: 'Date Created'
      },
      {
        name: 'last_updated',
        label: 'Last Updated'
      },
      {
        name: 'name',
        label: 'Client Name'
      },
      {
        name: 'description',
        label: 'Description'
      },
      {
        name: 'public_preview_key',
        label: 'Public Preview Key'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['Manta Video Components']}
          handleCreateModalClose={this.handleCreateModalClose}
          tableActionsList={actionsList}
          dataList={videoComponentData}
          contentHeaderActions={contentHeaderActions}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          isDeleteModalOpen={isDeleteModalOpen}
          deleteActionList={deleteActionList}
          deleteContent={warningConstants.WARNING_DELETE_COMPONENT}
          error={error}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}
          isEditModalOpen={isEditModalOpen}
          fieldList={fieldList}
          createEditBtnContent={createEditBtnContent}
          createEditHeaderContent={createEditHeaderContent}
        />
      </DashboardTemplate>
    );
  }
}

MantaVideoComponents.propTypes = {
  loadVideoComponentToCreate: PropTypes.func.isRequired,
  loadVideoComponentToDelete: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  loadVideoComponentToUpdate: PropTypes.func.isRequired,
  loadSelectedVideoComponent: PropTypes.func.isRequired,
  loadVideoComponentData: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  videoComponentData: PropTypes.array.isRequired
};

export default MantaVideoComponents;
