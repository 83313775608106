import React from 'react';
import { Table, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { mapKeys, sortBy, isEmpty, omit, size, includes, indexOf, uniq } from 'lodash';

import arrayDifference from 'shared/scripts/arrayDifference';
import arrayMove from 'shared/scripts/arrayMove';
import sortObjectProps from 'shared/scripts/sortObjectProps';
import NoContentFoundContainer from 'components/molecules/NoContentFoundContainer';
import DataTableBodyRows from 'components/molecules/DataTableBodyRows';
import DataTableHeaderRow from 'components/molecules/DataTableHeaderRow';

import styles from './DataTable.module.scss';

export class DataTable extends React.PureComponent {
  getParsedAndOmmitedBodyVals = columnNames => {
    const { filterOut, dataList } = this.props;
    let omittedList = [];
    const columnCount = columnNames.length;

    // Go through elements to prepare a list of omitted values
    dataList.forEach(item => {
      let dataObject = item;
      let differenceList = [];

      // If object size is less than column header count
      // Push empty properties to the object
      // This is needed because there are inconsistencies between object properties
      if (size(dataObject) !== columnCount) {
        let bodyColumnNames = [];
        mapKeys(dataObject, (_value, key) => bodyColumnNames.push(key));

        differenceList = arrayDifference(columnNames, uniq(bodyColumnNames));

        if (differenceList) {
          let diffObject = {};
          differenceList.forEach(diffItem => {
            diffObject = { ...diffObject, ...{ [diffItem]: null } };
          });

          dataObject = { ...dataObject, ...diffObject };
        }
      }

      dataObject = sortObjectProps(dataObject);

      omittedList.push(omit(dataObject, filterOut));
    });

    return omittedList;
  };

  getColumnNames = () => {
    const { filterOut, dataList } = this.props;
    const headerTitles = [];

    let sortedHeaderTitles = [];
    let idIndex;

    dataList.forEach(item =>
      mapKeys(item, (_value, key) => {
        // Do not push if the key matches filterOut array item
        // or any other item in the array
        if (!includes(filterOut, key) && !includes(headerTitles, key)) {
          headerTitles.push(key);
        }
      })
    );

    sortedHeaderTitles = sortBy(headerTitles);
    idIndex = indexOf(sortedHeaderTitles, 'id');

    if (idIndex) {
      arrayMove(sortedHeaderTitles, idIndex, 0);
    }

    return sortedHeaderTitles;
  };

  getContent = () => {
    const { actionsList, tableHeaderLabels } = this.props;
    const columnNames = this.getColumnNames();
    const bodyVals = this.getParsedAndOmmitedBodyVals(columnNames);

    return (
      <>
        <Table.Header fullWidth>
          <DataTableHeaderRow
            contentList={columnNames}
            tableHeaderLabels={tableHeaderLabels}
            actionsCount={actionsList.length}
          />
        </Table.Header>

        <Table.Body>
          <DataTableBodyRows contentList={bodyVals} actionsList={actionsList} />
        </Table.Body>
      </>
    );
  };

  render () {
    const { dataList, isLoading } = this.props;

    if (isEmpty(dataList) && isLoading) {
      return null;
    }

    if (isEmpty(dataList)) {
      return <NoContentFoundContainer />;
    }

    return (
      <Segment className={styles.root}>
        <Table structured celled size='small' color='blue' compact='very'>
          {this.getContent()}
        </Table>
      </Segment>
    );
  }
}

DataTable.defaultProps = {
  actionsList: []
};

DataTable.propTypes = {
  tableHeaderLabels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  dataList: PropTypes.arrayOf(PropTypes.object),
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      type: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  ),
  filterOut: PropTypes.arrayOf(PropTypes.string)
};

export default DataTable;
