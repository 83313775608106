export function jsonResponseHandler (response) {
  return _responseHandler(response);
}

async function _responseHandler (response) {
  if (response.status < 200 || response.status >= 300) {
    console.error(response.status);

    if (response.status === 401 && window.location.pathname !== '/login') {
      window.location.replace('/login');
    }

    throw await response.json();
  }

  let body = null;
  if (response.status !== 204) {
    body = await response.json();
  }

  return {
    status: response.status,
    body
  };
}

export function getIsAdminFromToken (token) {
  if (token) {
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
    const isAdmin = decodedJwtData.roleList.includes(ROLE_SUPER_ADMIN);

    return isAdmin;
  }

  return false;
}
