// GENERIC
export const ERROR_GENERIC = 'Sorry, something went wrong. Please try again.';
export const ERROR_GENERIC_REFRESH =
  'Sorry, something went wrong. Please refresh the page and try again.';
export const ERROR_PAGE_404 = '404. Page Not Found.';
export const ERROR_GENERIC_REQUIRED_FIELD = 'Please fill out this field';
export const ERROR_NO_CONTENT = 'No content to display.';
export const ERROR_INVALID_URL = 'Please enter valid URL';
export const ERROR_BOUNDARY_MESSAGE = 'Sorry, something went wrong.';

// LOGIN
export const ERROR_LOGIN_NO_EMAIL_FILLED = 'Please enter email.';
export const ERROR_LOGIN_NO_PASSWORD_FILLED = 'Please enter password.';
export const ERROR_LOGIN_INVALID_CREDENTIALS = 'Please enter valid email and password.';
export const ERROR_LOGIN_NO_ACCESS_RIGHTS =
  "Sorry, your account doesn't have access rights to login.";
