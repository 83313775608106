import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const Link = React.memo(({ href, newTab }) => {
  const targetValue = newTab ? '_blank' : '_self';
  const relValue = newTab ? 'noopener noreferrer' : null;

  return (
    <a href={href} target={targetValue} rel={relValue}>
      <span>{href}</span>
      <Icon name='linkify' />
      {newTab && <FaExternalLinkAlt />}
    </a>
  );
});

Link.defaultProps = {
  newTab: false
};

Link.propTypes = {
  newTab: PropTypes.bool,
  href: PropTypes.string.isRequired
};

export default Link;
