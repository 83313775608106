import MarketsService from 'services/MarketsService';
import ClientsService from 'services/ClientsService';
import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';

export const loadClientList = () => {
  return async dispatch => {
    await ClientsService.getAllClients()
      .then(response => {
        const clientesponse = response.body;
        const parsedClients = [];

        clientesponse.forEach(item =>
          parsedClients.push({
            key: `${item.id}-${item.name}`,
            text: item.name,
            value: item.id
          })
        );
        dispatch(actions.setClientList(parsedClients));
        dispatch(loadResetError());
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCreateMarket = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    MarketsService.postMarket(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadMarketList());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadMarketToUpdate = ({ data, id }, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    MarketsService.patchMarket(data, id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadMarketList());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadMarketList = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    MarketsService.getAllMarkets()
      .then(response => {
        const marketResponse = response.body;
        let parsedMarkets = [];

        marketResponse.forEach(item =>
          parsedMarkets.push({
            id: item.id,
            name: item.name,
            country_code: item.country_code,
            code_name: item.code_name,
            rdam_enabled: item.rdam_enabled,
            client_id: item.client.id
          })
        );

        dispatch(actions.setMarketList(parsedMarkets));
        dispatch(loadResetError());
        dispatch(actions.setDataLoadingState(false));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadSelectedMarket = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedMarketList(getState(), id);
  };
};
