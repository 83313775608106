import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import * as errorConstants from 'shared/constants/errorConstants';

const NoContentFoundContainer = React.memo(({ content }) => (
  <Segment placeholder>
    <Header icon>{content}</Header>
  </Segment>
));

NoContentFoundContainer.defaultProps = {
  content: errorConstants.ERROR_NO_CONTENT
};

NoContentFoundContainer.propTypes = {
  content: PropTypes.string
};

export default NoContentFoundContainer;
