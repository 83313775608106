import { find } from 'lodash';

export const getIsDataLoading = state => state.companies.isDataLoading;

export const getIsRequestLoading = state => state.companies.isRequestLoading;

export const getError = state => state.companies.error;

export const getCompaniesList = state => state.companies.companiesList;

export const getSelectedConfigList = (state, id) => find(state.companies.companiesList, { id: id });
