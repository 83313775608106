import * as types from './types';

const ACTION_HANDLERS = {
  [types.REPORT_GROUPS_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.REPORT_GROUPS_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.REPORT_GROUPS_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.REPORT_GROUPS_SET_GROUPS]: (state, action) => ({
    ...state,
    groups: action.payload.groups
  }),
  [types.REPORT_GROUPS_SET_MARKETS]: (state, action) => ({
    ...state,
    markets: action.payload.markets
  }),
  [types.REPORT_GROUPS_SET_FILTER_STRING]: (state, action) => ({
    ...state,
    filterString: action.payload.filterString
  }),
  [types.REPORT_GROUPS_SET_REMOVE_ITEM_FROM_STATE]: (state, action) => ({
    ...state,
    groups: state.groups.filter(item => item.id !== action.payload.id)
  })
};

const initialState = {
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  groups: [],
  markets: [],
  filterString: ''
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
