import history from 'shared/scripts/history';

export const CallService = {
  unauthorizedCall: function (method, route, body = null, getParam = null) {
    let httpObject = {
      method,
      headers: {}
    };
    if (body && method !== 'GET') {
      httpObject.body = JSON.stringify(body);
    }
    let url = process.env.REACT_APP_API_URL + route + generateGetString(getParam);

    return fetch(url, httpObject);
  },
  authorizedCall: function (method, route, body = null, getParam = null) {
    const token = window.localStorage.jwttoken;
    handleLocalStorageTokenCheck();

    const httpObject = {
      method,
      headers: { Authorization: 'Bearer ' + token }
    };

    if (body && method !== 'GET') {
      httpObject.body = JSON.stringify(body);
    }

    let url = process.env.REACT_APP_API_URL + route + generateGetString(getParam);

    return fetch(url, httpObject);
  },
  authorizedCallReports: function (method, route, body = null, getParam = null) {
    const token = window.localStorage.jwttoken;
    handleLocalStorageTokenCheck();

    const httpObject = {
      method,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    };

    if (body && method !== 'GET') {
      httpObject.body = JSON.stringify(body);
    }

    let url = process.env.REACT_APP_API_REPORTS_URL + route + generateGetString(getParam);

    return fetch(url, httpObject);
  }
};

function handleLocalStorageTokenCheck () {
  const token = window.localStorage.jwttoken;
  if (token === null || token === undefined) {
    console.error('No token has been saved in browsers localstorage. Redirected to the login page');
    history.push('/login');
  }
}

function generateGetString (getParam = null) {
  let getString = '';

  const userId = window.localStorage.userId;
  if (userId) {
    getString += userId ? 'user_id=' + userId + '&' : '';
  }

  if (getParam) {
    for (var key in getParam) {
      getString += key + '=' + getParam[key] + '&';
    }
  }

  if (getString !== '') {
    getString = '?' + getString;
  }

  return getString;
}

export default CallService;
