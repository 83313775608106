import React from 'react';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import styles from './SideMenu.module.scss';

export const SideMenu = ({ location, history }) => {
  const handleItemClick = location => history.push(location);

  const currentLocation = location.pathname;
  const sideMenuContent = [
    {
      content: 'dashboard',
      active: currentLocation === '/',
      path: '/'
    },
    {
      content: 'reports',
      active: includes(currentLocation, '/reports'),
      path: '/reports'
    },
    {
      content: 'clients',
      active: includes(currentLocation, '/clients'),
      path: '/clients'
    },
    {
      content: 'markets',
      active: includes(currentLocation, '/markets'),
      path: '/markets'
    },
    {
      content: 'user email configurations',
      active: includes(currentLocation, '/useremailconfigurations'),
      path: '/useremailconfigurations'
    },
    {
      content: 'companies',
      active: includes(currentLocation, '/companies'),
      path: '/companies'
    },
    {
      content: 'manta video components',
      active: includes(currentLocation, '/mantavideocomponents'),
      path: '/mantavideocomponents'
    },
    {
      content: 'user applications',
      active: includes(currentLocation, '/userapplications'),
      path: '/userapplications'
    }
  ];

  return (
    <div className={styles.root}>
      <Menu inverted vertical fluid size='large'>
        {sideMenuContent.map((item, index) => (
          <Menu.Item
            key={`side-menu-item-${index}`}
            name={item.content}
            active={item.active}
            onClick={() => handleItemClick(item.path)}
          />
        ))}
      </Menu>
    </div>
  );
};

SideMenu.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SideMenu);
