import { find } from 'lodash';

export const getIsDataLoading = state => state.clients.isDataLoading;

export const getIsRequestLoading = state => state.clients.isRequestLoading;

export const getError = state => state.clients.error;

export const getClientData = state => state.clients.clientData;

export const getSelectedClient = (state, id) => find(state.clients.clientData, { id: id });
