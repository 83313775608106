import React from 'react';
import PropTypes from 'prop-types';
import { toInteger } from 'lodash';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';
import * as warningConstants from 'shared/constants/warningConstants';

class Reports extends React.PureComponent {
  state = {
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    reportId: null,
    initialValues: {},
    createEditHeaderContent: '',
    createEditBtnContent: '',
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    // Dunno if this is the best way to get selected group id
    const selectedGroupId = toInteger(this.props.match.params.id);
    this.props.loadReportGroupId(selectedGroupId);
    this.props.loadReports(selectedGroupId);
  }

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      reportId: null,
      createEditHeaderContent: '',
      createEditBtnContent: '',
      handleCreateEditAction: () => {}
    });
  };
  handleEditModalOpen = id => {
    const selectedReport = this.props.loadSelectedReport(id);
    this.setState({
      isEditModalOpen: true,
      initialValues: {
        reportGroupId: this.props.groupId,
        name: selectedReport.name,
        orderIndex: toInteger(selectedReport.orderIndex),
        dataramaUrl: selectedReport.dataramaUrl
      },
      reportId: selectedReport.id,
      createEditHeaderContent: 'Edit report',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
  };
  handleCreateModalOpen = () =>
    this.setState({
      isEditModalOpen: true,
      initialValues: {
        reportGroupId: this.props.groupId,
        orderIndex: 0
      },
      createEditHeaderContent: 'Create new report',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };
  handleDeleteModalOpen = id =>
    this.setState({
      isDeleteModalOpen: true,
      reportId: id
    });
  handleDeleteModalClose = () => {
    this.resetModalState();
    this.setState({
      isDeleteModalOpen: false
    });
  };
  handleDeleteSubmit = () => {
    const { error, loadReportToDelete } = this.props;
    const { reportId } = this.state;

    loadReportToDelete(reportId, () => {
      if (!error) {
        this.handleDeleteModalClose();
      }
    });
  };
  handleSubmitNew = data => {
    const { error, loadCreateReport } = this.props;

    loadCreateReport(data, () => {
      if (!error) {
        this.handleCreateModalClose();
      }
    });
  };
  handleSubmitUpdate = data => {
    const { error, loadReportToUpdate } = this.props;
    const { reportId } = this.state;
    const params = { data, id: reportId };

    loadReportToUpdate(params, () => {
      if (!error) {
        this.handleCreateModalClose();
      }
    });
  };

  render () {
    const { isDataLoading, isRequestLoading, reportData, error } = this.props;
    const {
      id,
      isDeleteModalOpen,
      isEditModalOpen,
      initialValues,
      createEditHeaderContent,
      createEditBtnContent,
      handleCreateEditAction
    } = this.state;
    const actionsList = [
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteModalOpen
      }
    ];
    const contentHeaderActions = [
      {
        content: 'Create new report',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];
    const deleteActionList = [
      {
        content: 'Cancel',
        type: 'standard',
        action: this.handleDeleteModalClose
      },
      {
        content: 'Delete',
        type: 'delete',
        action: this.handleDeleteSubmit
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Report name',
        name: 'name',
        required: true
      },
      {
        type: 'string',
        label: 'Datorama URL',
        name: 'dataramaUrl',
        required: true,
        link: true
      },
      {
        type: 'number',
        label: 'Order index',
        name: 'orderIndex',
        required: false
      }
    ];

    const tableHeaderLabels = [
      {
        name: 'dataramaUrl',
        label: 'Datorama URL'
      },
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'name',
        label: 'Report name'
      },
      {
        name: 'orderIndex',
        label: 'Order Index'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          tableHeaderLabels={tableHeaderLabels}
          actionId={id}
          breadCrumbContent={['Report Groups', reportData.name]}
          handleCreateModalClose={this.handleCreateModalClose}
          handleDeleteModalClose={this.handleDeleteModalClose}
          tableActionsList={actionsList}
          dataList={reportData.reports}
          contentHeaderActions={contentHeaderActions}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          isDeleteModalOpen={isDeleteModalOpen}
          deleteActionList={deleteActionList}
          deleteContent={warningConstants.WARNING_DELETE_REPORT}
          error={error}
          handleCreateEditAction={handleCreateEditAction}
          initialValues={initialValues}
          isEditModalOpen={isEditModalOpen}
          fieldList={fieldList}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
        />
      </DashboardTemplate>
    );
  }
}

Reports.propTypes = {
  loadReportGroupId: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  loadReportToUpdate: PropTypes.func.isRequired,
  loadSelectedReport: PropTypes.func.isRequired,
  loadCreateReport: PropTypes.func.isRequired,
  loadReports: PropTypes.func.isRequired,
  loadReportToDelete: PropTypes.func.isRequired,
  groupId: PropTypes.number,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  reportData: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default Reports;
