import * as types from './types';

const ACTION_HANDLERS = {
  [types.USEREMAILCONFIG_SET_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.payload.isLoading
  }),

  [types.USEREMAILCONFIG_SET_DATA_LOADING_STATE]: (state, action) => ({
    ...state,
    isDataLoading: action.payload.isDataLoading
  }),
  [types.USEREMAILCONFIG_SET_REQUEST_LOADING_STATE]: (state, action) => ({
    ...state,
    isRequestLoading: action.payload.isRequestLoading
  }),
  [types.USEREMAILCONFIG_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.USEREMAILCONFIG_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.USEREMAILCONFIG_SET_EMAIL_CONFIG_DATA]: (state, action) => ({
    ...state,
    configList: action.payload.configList
  })
};

const initialState = {
  isLoading: true,
  isDataLoading: true,
  isRequestLoading: false,
  error: '',
  configList: []
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
