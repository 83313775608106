import React from 'react';
import { Modal, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';

import CTA from 'components/molecules/CTA';

class ModalMessage extends React.PureComponent {
  getButtons = () =>
    this.props.actionsList.map((item, index) => (
      <CTA
        key={`modal-cta-${index}`}
        type={item.type}
        content={item.content}
        onClick={() => item.action(this.props.actionId)}
        floated={item.floated}
        isLoading={this.props.isLoading}
      />
    ));

  getErrorState = ({ errorContent } = this.props) => !isNull(errorContent) && errorContent !== '';

  getWarningState = ({ warningContent } = this.props) =>
    !isNull(warningContent) && warningContent !== '';

  render () {
    const {
      headerContent,
      mainContent,
      open,
      onModalClose,
      actionsList,
      errorContent,
      warningContent
    } = this.props;

    if (!open) {
      return null;
    }

    return (
      <Modal size='mini' dimmer='blurring' open={open} onClose={onModalClose}>
        {headerContent && <Modal.Header>{headerContent}</Modal.Header>}

        <Modal.Content>
          <p>{mainContent}</p>
          {this.getErrorState() && <Message negative content={errorContent} />}
          {this.getWarningState() && <Message warning content={warningContent} />}
        </Modal.Content>

        {actionsList && <Modal.Actions>{this.getButtons()}</Modal.Actions>}
      </Modal>
    );
  }
}

ModalMessage.defaultProps = {
  errorContent: null,
  warningContent: null,
  isLoading: false
};

ModalMessage.propTypes = {
  onModalClose: PropTypes.func,
  headerContent: PropTypes.string,
  mainContent: PropTypes.string,
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      type: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  ),
  actionId: PropTypes.number,
  open: PropTypes.bool,
  errorContent: PropTypes.string,
  warningContent: PropTypes.string,
  isLoading: PropTypes.bool
};

export default ModalMessage;
