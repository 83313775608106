import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.USERAPPLICATIONS_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.USERAPPLICATIONS_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.USERAPPLICATIONS_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.USERAPPLICATIONS_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setUserApplicationList = userApplicationList => {
  return {
    type: types.USERAPPLICATIONS_SET_APPLICATION_DATA,
    payload: { userApplicationList }
  };
};

export const setDeleteUserApplicationAssociationData = id => {
  return {
    type: types.USERAPPLICATIONS_SET_REMOVE_ITEM_FROM_STATE,
    payload: { id }
  };
};

export const setAvailableApplicationData = availableApplications => {
  return {
    type: types.USERAPPLICATIONS_SET_AVAILABLE_APPLICATION_DATA,
    payload: { availableApplications }
  };
};

export const setAvailableUserData = availableUsers => {
  return {
    type: types.USERAPPLICATIONS_SET_AVAILABLE_USER_DATA,
    payload: { availableUsers }
  };
};
