import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import * as warningConstants from 'shared/constants/warningConstants';

export const WarningOfflineMessage = ({ attached }) => (
  <Message attached={attached && 'bottom'} warning size='small'>
    {warningConstants.WARNING_OFFLINE_MODE}
  </Message>
);

WarningOfflineMessage.defaultProps = {
  attached: false
};

WarningOfflineMessage.propTypes = {
  attached: PropTypes.bool
};

export default WarningOfflineMessage;
