import React from 'react';
import PropTypes from 'prop-types';
import { Message, Segment } from 'semantic-ui-react';

import { ERROR_GENERIC_REFRESH } from 'shared/constants/errorConstants';

class ErrorBoundary extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch (error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render () {
    if (this.state.hasError) {
      return (
        <Segment size='large'>
          <Message negative>
            <Message.Header>{ERROR_GENERIC_REFRESH}</Message.Header>
          </Message>
        </Segment>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default ErrorBoundary;
