import { indexOf } from 'lodash';
import arrayMove from './arrayMove';

const sortObjectProps = object => {
  let sorted = {};
  let key;
  let array = [];

  for (key in object) {
    if (object.hasOwnProperty(key)) {
      array.push(key);
    }
  }

  array.sort();
  const oldIndex = indexOf(array, 'id');

  if (oldIndex) {
    array = arrayMove(array, oldIndex, 0);
  }

  for (key = 0; key < array.length; key++) {
    sorted[array[key]] = object[array[key]];
  }
  return sorted;
};

export default sortObjectProps;
