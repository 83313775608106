import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'components/atoms/ErrorBoundary';
import styles from './LoginTemplate.module.scss';

export const LoginTemplate = ({ children }) => (
  <div className={styles.root}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

LoginTemplate.propTypes = {
  children: PropTypes.node
};

export default LoginTemplate;
