export const getIsDataLoading = state => state.userApplications.isDataLoading;

export const getIsRequestLoading = state => state.userApplications.isRequestLoading;

export const getError = state => state.userApplications.error;

export const getUserApplicationList = state => state.userApplications.userApplicationList;

export const getAvailableApplicationList = state => state.userApplications.availableApplications;

export const getAvailableUserList = state => state.userApplications.availableUsers;
