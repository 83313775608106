import { connect } from 'react-redux';
import UserEmailConfigurations from '../components/UserEmailConfigurations';
import {
  loadCreateConfigList,
  loadConfigListToUpdate,
  loadConfigList,
  loadResetError,
  loadSelectedConfigList
} from '../redux/operations';

import * as selector from '../redux/selectors';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    configList: selector.getConfigList(state)
  }),
  {
    loadSelectedConfigList,
    loadResetError,
    loadConfigList,
    loadConfigListToUpdate,
    loadCreateConfigList
  }
)(UserEmailConfigurations);
