import CompaniesService from 'services/CompaniesService';
import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadCreateCompaniesList = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    CompaniesService.postCompany(data)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadCompaniesList());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCompaniesListToUpdate = ({ data, id }, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    CompaniesService.patchCompany(data, id)
      .then(() => {
        dispatch(actions.setRequestLoadingState(false));
        dispatch(loadCompaniesList());
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadCompaniesList = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    CompaniesService.getAllCompanies()
      .then(response => {
        dispatch(actions.setCompaniesList(response.body));
        dispatch(loadResetError());
        dispatch(actions.setDataLoadingState(false));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadSelectedCompaniesList = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedConfigList(getState(), id);
  };
};
