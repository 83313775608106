import React from 'react';
import { Segment } from 'semantic-ui-react';
import DashboardTemplate from 'components/templates/DashboardTemplate';

export const Dashboard = () => {
  return (
    <DashboardTemplate>
      <Segment>Dashboard page</Segment>
    </DashboardTemplate>
  );
};

export default Dashboard;
