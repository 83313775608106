import React from 'react';
import { Segment } from 'semantic-ui-react';

import { ERROR_PAGE_404 } from 'shared/constants/errorConstants';
import NotFoundTemplate from 'components/templates/NotFoundTemplate';
import NoContentFoundContainer from 'components/molecules/NoContentFoundContainer';

export const NotFound = () => {
  return (
    <NotFoundTemplate>
      <Segment textAlign='center'>
        <NoContentFoundContainer content={ERROR_PAGE_404} />
      </Segment>
    </NotFoundTemplate>
  );
};

export default NotFound;
