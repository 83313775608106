import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.CLIENTS_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.CLIENTS_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.CLIENTS_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.CLIENTS_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setClientData = clientData => {
  return {
    type: types.CLIENTS_SET_CLIENT_DATA,
    payload: { clientData }
  };
};
