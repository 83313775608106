import React from 'react';
import { Table } from 'semantic-ui-react';
import { isBoolean, isEmpty, isArray, isObject } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

import Link from 'components/molecules/Link';
import validUrl from 'shared/scripts/validUrl';
import validDate from 'shared/scripts/validDate';
import CTA from 'components/molecules/CTA';

export const DataTableBodyRows = React.memo(({ contentList, actionsList }) => {
  const getCellValue = value => {
    if (isBoolean(value)) {
      return String(value);
    }
    if (validUrl(value)) {
      return <Link href={value} newTab />;
    }
    if (validDate(value)) {
      return moment(value).format('LLL');
    }
    if (isArray(value)) {
      return null;
    }
    if (isObject(value)) {
      return JSON.stringify(value, 0, 2);
    }

    return value;
  };

  const getButtons = id =>
    isEmpty(actionsList)
      ? null
      : actionsList.map((item, index) => (
        <Table.Cell key={`action-${index}`} collapsing textAlign='center'>
          <CTA type={item.type} content={item.content} onClick={() => item.action(id)} />
        </Table.Cell>
      ));

  return !isArray(contentList)
    ? null
    : contentList.map(item => (
      <Table.Row key={`body-row-${item.id}`}>
        {Object.keys(item).map((itemValue, index) => (
          <Table.Cell key={`${item.id}-${index}`}>{getCellValue(item[itemValue])}</Table.Cell>
        ))}

        {getButtons(item.id)}
      </Table.Row>
    ));
});

DataTableBodyRows.propTypes = {
  contentList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
  ),
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      type: PropTypes.string,
      action: PropTypes.func.isRequired
    })
  )
};

export default DataTableBodyRows;
