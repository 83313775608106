import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const CompaniesService = {
  getAllVideoComponents: function () {
    return CallService.authorizedCall('GET', `/creativecomponents`).then(jsonResponseHandler);
  },
  deleteVideoComponent: function (Id) {
    return CallService.authorizedCall('DELETE', `/creativecomponents/${Id}`).then(
      jsonResponseHandler
    );
  },
  postVideoComponent: function (data) {
    // Create
    return CallService.authorizedCall('POST', `/creativecomponents`, data).then(
      jsonResponseHandler
    );
  },
  updateVideoComponent: function (data) {
    // Update
    return CallService.authorizedCall('POST', `/creativecomponentinstances`, data).then(
      jsonResponseHandler
    );
  }
};

export default CompaniesService;
