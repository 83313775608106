import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.REPORTS_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.REPORTS_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.REPORTS_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.REPORTS_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setReports = reportData => {
  return {
    type: types.REPORTS_SET_REPORTS,
    payload: { reportData }
  };
};

export const setNewReport = reportData => {
  return {
    type: types.REPORTS_SET_NEW_REPORT,
    payload: { reportData }
  };
};

export const setReportGroupId = groupId => {
  return {
    type: types.REPORTS_SET_GROUP_ID,
    payload: { groupId }
  };
};

export const setDeleteReportData = id => {
  return {
    type: types.REPORTS_SET_REMOVE_ITEM_FROM_STATE,
    payload: { id }
  };
};
