import React from 'react';
import {
  Modal,
  Input,
  Form as SemanticForm,
  Message,
  Dropdown,
  Checkbox,
  Label
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { toInteger } from 'lodash';
import SearchableUsersDropdown from './SearchableUsersDropdown';

import validUrl from 'shared/scripts/validUrl';
import CTA from 'components/molecules/CTA';
import * as errorConstants from 'shared/constants/errorConstants';

class ModalCreateEdit extends React.PureComponent {
  getButtons = (handleSubmit, pristine, submitting) => {
    const disabled = pristine || submitting;
    const { saveBtnContent, onModalClose, isLoading } = this.props;
    // handleSubmit handler comes from react-final-form.
    // Do not rename it as onSubmit!
    return (
      <>
        <CTA
          key='modal-cta-cancel'
          content='Cancel'
          onClick={onModalClose}
          disabled={submitting || isLoading}
        />
        <CTA
          key='modal-cta-save'
          content={saveBtnContent}
          onClick={handleSubmit}
          disabled={disabled || isLoading}
          isLoading={isLoading}
        />
      </>
    );
  };

  handleCheckboxSelection = (input, { checked }) => input.onChange(checked);

  handleArrayValueSelection = (input, { value }) => input.onChange(value);

  handleNumberInput = (input, { value }) => input.onChange(toInteger(value));

  getFormField = (input, item) => {
    switch (item.type) {
      case 'string':
        return (
          <Input
            fluid
            size='small'
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            disabled={item.disabled}
          />
        );
      case 'number':
        return (
          <Input
            fluid
            size='small'
            name={input.name}
            value={input.value}
            onChange={(_e, value) => this.handleNumberInput(input, value)}
            disabled={item.disabled}
            type='number'
          />
        );
      case 'array':
        return (
          <Dropdown
            options={item.options}
            selection
            search
            value={input.value}
            onChange={(_e, value) => this.handleArrayValueSelection(input, value)}
            name={input.name}
            loading={item.loading}
          />
        );
      case 'bool':
        return (
          <Checkbox
            type='checkbox'
            onChange={(_e, value) => this.handleCheckboxSelection(input, value)}
            name={input.name}
            checked={!!input.value}
          />
        );

      case 'serverUsersSearch':
        return (
          <SearchableUsersDropdown
            onChange={(_e, value) => this.handleArrayValueSelection(input, value)}
            name={input.name}
          />
        );

      default:
        return (
          <Input
            fluid
            size='small'
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            disabled={item.disabled}
          />
        );
    }
  };

  getFieldValidationMethod = (item, value) => {
    let validResult;
    if (item.required) {
      validResult = this.handleRequiredValidation(value);
    }
    if (value && item.link) {
      validResult = this.handleLinkValidation(value);
    }

    return validResult;
  };

  getFieldContent = () => {
    return this.props.fieldList.map((item, index) => (
      <Field
        validate={value => this.getFieldValidationMethod(item, value)}
        key={`${item.name}-${index}`}
        name={item.name}
        render={({ input, meta }) => (
          <SemanticForm.Field required={item.required} error={meta.error && meta.touched}>
            <label>{item.label}</label>
            {this.getFormField(input, item)}

            {meta.error && meta.touched && (
              <Label basic color='red' pointing content={meta.error} />
            )}
          </SemanticForm.Field>
        )}
      />
    ));
  };

  handleRequiredValidation = value =>
    value ? undefined : errorConstants.ERROR_GENERIC_REQUIRED_FIELD;

  handleLinkValidation = value => (validUrl(value) ? undefined : errorConstants.ERROR_INVALID_URL);

  getErrorState = ({ errorContent } = this.props) => errorContent && errorContent !== '';

  render () {
    const {
      headerContent,
      open,
      onModalClose,
      onSubmit,
      initialValues = {},
      errorContent
    } = this.props;

    if (!open) {
      return null;
    }

    return (
      <Modal size='tiny' dimmer='blurring' open={open} onClose={onModalClose}>
        {headerContent && <Modal.Header>{headerContent}</Modal.Header>}

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, pristine, submitting }) => (
            <>
              <Modal.Content>
                <SemanticForm size='large' onSubmit={handleSubmit}>
                  {this.getFieldContent()}
                </SemanticForm>
                {this.getErrorState() && <Message negative content={errorContent} />}
              </Modal.Content>

              <Modal.Actions>{this.getButtons(handleSubmit, pristine, submitting)}</Modal.Actions>
            </>
          )}
        />
      </Modal>
    );
  }
}

ModalCreateEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  errorContent: PropTypes.string,
  headerContent: PropTypes.string,
  fieldList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['number', 'string', 'array', 'bool', 'serverUsersSearch']),
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      ),
      loading: PropTypes.bool,
      link: PropTypes.bool
    })
  ),
  saveBtnContent: PropTypes.string,
  open: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ModalCreateEdit;
