import UserEmailConfigurationService from 'services/UserEmailConfigurationService';
import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setDataLoadingState(false));
    dispatch(actions.setRequestLoadingState(false));
  };
};

export const loadSelectedConfigList = id => {
  return (_dispatch, getState) => {
    return selectors.getSelectedConfigList(getState(), id);
  };
};

export const loadCreateConfigList = (data, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    UserEmailConfigurationService.postUserEmailConfigurations(data)
      .then(() => {
        dispatch(loadConfigList());
        dispatch(actions.setRequestLoadingState(false));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadConfigListToUpdate = ({ data, id }, resolve) => {
  return dispatch => {
    dispatch(actions.setRequestLoadingState(true));

    UserEmailConfigurationService.patchUserEmailConfigurations(data, id)
      .then(() => {
        dispatch(loadConfigList());
        dispatch(actions.setRequestLoadingState(false));
        resolve();
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};

export const loadConfigList = () => {
  return dispatch => {
    dispatch(actions.setDataLoadingState(true));

    UserEmailConfigurationService.getAllUserEmailConfigurations()
      .then(response => {
        dispatch(actions.setMarketList(response.body));
        dispatch(loadResetError());
        dispatch(actions.setDataLoadingState(false));
      })
      .catch(() => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
      });
  };
};
