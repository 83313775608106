import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const UserApplications = {
  getAllUserApplications: function () {
    return CallService.authorizedCall('GET', '/userapplications').then(jsonResponseHandler);
  },
  getUserApplicationsById: function (id) {
    return CallService.authorizedCall('GET', `/userapplications/${id}`).then(jsonResponseHandler);
  },
  deleteUserApplicationsById: function (id) {
    return CallService.authorizedCall(
      'DELETE', `/userapplications/${id}`
    ).then(jsonResponseHandler);
  },
  postUserApplications: function (data) {
    return CallService.authorizedCall('POST', '/userapplications', data).then(
      jsonResponseHandler
    );
  }
};

export default UserApplications;
