import { get } from 'lodash';

import * as actions from './actions';
import * as selectors from './selectors';
import * as errorConstants from 'shared/constants/errorConstants';
import AuthService from 'services/AuthService';
import history from 'shared/scripts/history';
import { getIsAdminFromToken } from 'services/utils';

const RESPONSE_INVALID_PARAMS = 'Invalid params provided';
const RESPONSE_INVALID_CREDENTIALS = 'auth required';
const TOKEN_EXPIRATION = 1000;

export const loadSubmitedLoginData = userData => {
  return async dispatch => {
    dispatch(actions.setLoadingState(true));
    dispatch(actions.resetError());

    AuthService.postLogin(userData)
      .then(async response => {
        const { token } = response.body;

        if (token && getIsAdminFromToken(token)) {
          localStorage.setItem('jwttoken', token);
          dispatch(loadUserData());
        } else {
          dispatch(loadError(errorConstants.ERROR_LOGIN_NO_ACCESS_RIGHTS));
        }
      })
      .catch(error => {
        switch (error.errorMessage) {
          case RESPONSE_INVALID_PARAMS:
            dispatch(loadError(errorConstants.ERROR_LOGIN_INVALID_CREDENTIALS));
            break;
          case RESPONSE_INVALID_CREDENTIALS:
            dispatch(loadError(errorConstants.ERROR_LOGIN_INVALID_CREDENTIALS));
            break;
          default:
            dispatch(loadError(errorConstants.ERROR_GENERIC));
            break;
        }
        throw error;
      });
  };
};

export const loadResetError = () => {
  return dispatch => {
    dispatch(actions.resetError());
    dispatch(actions.setLoadingState(false));
  };
};

export const loadError = errorMessage => {
  return dispatch => {
    dispatch(actions.setError(errorMessage));
    dispatch(actions.setLoadingState(false));
  };
};

export const loadUserData = () => {
  return dispatch => {
    AuthService.getOwnData()
      .then(response => {
        localStorage.setItem('userId', response.body.id);
        localStorage.setItem('clientId', get(response.body, 'client_list[0].id', null));
        dispatch(actions.setUserId(response.body.id));
        dispatch(loadResetError());

        history.push('/');
      })
      .catch(error => {
        dispatch(loadError(errorConstants.ERROR_GENERIC));
        throw error;
      });
  };
};

export const reloadUserData = () => {
  return async (dispatch, getState) => {
    if (!selectors.getUserId(getState())) {
      const response = await AuthService.getOwnData();

      dispatch(actions.setUserId(response.body.id));
    }
  };
};

export const loadSubmitLogout = () => {
  return dispatch => {
    dispatch(actions.setLoadingState(true));
    AuthService.postLogout()
      .then(() => {
        dispatch(loadResetError());
        dispatch(actions.resetUserData());

        localStorage.clear();

        history.push('/login');
      })
      .catch(error => {
        localStorage.clear();
        dispatch(actions.resetUserData());
        dispatch(actions.setLoadingState(false));

        history.push('/login');
        throw error;
      });
  };
};

export const loadRefreshToken = () => {
  return dispatch => {
    AuthService.getRefreshToken()
      .then(response => {
        localStorage.setItem('jwttoken', response.body.token);
      })
      .catch(() => {
        dispatch(loadSubmitLogout());
      });
  };
};

export const loadTokenExpirationTime = () => {
  return dispatch => {
    const token = localStorage.getItem('jwttoken');

    if (getIsAdminFromToken(token)) {
      AuthService.getTokenExpirationTime()
        .then(response => {
          const timeRemaining = response.body.time_remaining;

          if (timeRemaining < TOKEN_EXPIRATION) {
            dispatch(loadRefreshToken());
          }
        })
        .catch(() => {
          dispatch(loadSubmitLogout());
        });
    } else {
      dispatch(loadSubmitLogout());
    }
  };
};
