import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const CTA = React.memo(({ content, type, onClick, floated, disabled, isLoading }) => {
  const parsedType = {
    standard: {
      color: 'pink',
      circular: true
    },
    standardEdit: {
      color: 'pink',
      icon: 'edit',
      circular: true
    },
    standardAdd: {
      color: 'pink',
      icon: 'add',
      circular: true
    },
    delete: {
      icon: 'delete',
      circular: true
    }
  };

  return (
    <Button
      {...parsedType[type]}
      onClick={onClick}
      size='small'
      content={content}
      floated={floated}
      disabled={disabled}
      loading={isLoading}
    />
  );
});

CTA.defaultProps = {
  type: 'standard',
  disabled: false,
  floated: null,
  isLoading: false
};

CTA.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['standard', 'delete', 'standardAdd', 'standardEdit']),
  floated: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default CTA;
