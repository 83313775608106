import CallService from 'services/CallService';
import { jsonResponseHandler } from './utils';

export const CompaniesService = {
  getAllCompanies: function () {
    return CallService.authorizedCall('GET', '/companies').then(jsonResponseHandler);
  },
  patchCompany: function (data, id) {
    return CallService.authorizedCall('PATCH', `/companies/${id}`, data).then(jsonResponseHandler);
  },
  postCompany: function (data) {
    return CallService.authorizedCall('POST', '/companies', data).then(jsonResponseHandler);
  }
};

export default CompaniesService;
