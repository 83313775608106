import { connect } from 'react-redux';

import {
  loadResetError,
  loadClientData,
  loadSelectedClient,
  loadClientToUpdate,
  loadCreateClient
} from '../redux/operations';
import * as selector from '../redux/selectors';
import Clients from '../components/Clients';

export default connect(
  state => ({
    isDataLoading: selector.getIsDataLoading(state),
    isRequestLoading: selector.getIsRequestLoading(state),
    error: selector.getError(state),
    clientData: selector.getClientData(state)
  }),
  {
    loadCreateClient,
    loadClientToUpdate,
    loadSelectedClient,
    loadClientData,
    loadResetError
  }
)(Clients);
