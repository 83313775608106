import * as types from './types';

export const setDataLoadingState = isDataLoading => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_SET_DATA_LOADING_STATE,
    payload: { isDataLoading }
  };
};

export const setRequestLoadingState = isRequestLoading => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_SET_REQUEST_LOADING_STATE,
    payload: { isRequestLoading }
  };
};

export const setError = error => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_SET_ERROR,
    payload: { error }
  };
};

export const resetError = () => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_RESET_ERROR,
    payload: { error: '' }
  };
};

export const setVideoComponentData = videoComponentData => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_SET_DATA,
    payload: { videoComponentData }
  };
};

export const setDeleteVideoComponentData = id => {
  return {
    type: types.MANTA_VIDEO_COMPONENTS_SET_REMOVE_ITEM_FROM_STATE,
    payload: { id }
  };
};
