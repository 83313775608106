import * as types from './types';

const ACTION_HANDLERS = {
  [types.LOGIN_SET_LOADING_STATE]: (state, action) => ({
    ...state,
    isLoading: action.payload.isLoading
  }),
  [types.LOGIN_SET_USER_ID]: (state, action) => ({
    ...state,
    userId: action.payload.userId
  }),
  [types.LOGIN_SET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.LOGIN_RESET_ERROR]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [types.LOGIN_RESET_USER_DATA]: (state, action) => ({
    ...state,
    userId: action.payload.userId
  })
};

const initialState = {
  isLoading: false,
  userId: '',
  error: ''
};

export default function reducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
