import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import TableView from 'components/organisms/TableView';
import DashboardTemplate from 'components/templates/DashboardTemplate';

class Markets extends React.PureComponent {
  state = {
    isEditModalOpen: false,
    id: null,
    initialValues: {},
    createEditHeaderContent: '',
    createEditBtnContent: '',
    handleCreateEditAction: () => {}
  };

  componentDidMount () {
    this.props.loadConfigList();
  }

  resetModalState = () => {
    this.props.loadResetError();
    this.setState({
      initialValues: {},
      id: null,
      createEditHeaderContent: '',
      createEditBtnContent: ''
    });
  };
  handleEditModalOpen = async id => {
    const omittedValues = omit(this.props.loadSelectedConfigList(id), [
      'date_created, last_updated, id'
    ]);
    this.setState({
      id: id,
      isEditModalOpen: true,
      initialValues: {
        ...omittedValues
      },
      createEditHeaderContent: 'Edit user email configuration',
      createEditBtnContent: 'Save',
      handleCreateEditAction: this.handleSubmitUpdate
    });
  };
  handleCreateModalOpen = () =>
    this.setState({
      isEditModalOpen: true,
      createEditHeaderContent: 'Create new user email configuration',
      createEditBtnContent: 'Create',
      handleCreateEditAction: this.handleSubmitNew
    });
  handleCreateModalClose = () => {
    this.resetModalState();
    this.setState({
      isEditModalOpen: false
    });
  };

  isErrorDefined = ({ error } = this.props) => error !== '' || error;

  handleSubmitNew = data => {
    const { loadCreateConfigList } = this.props;

    loadCreateConfigList(data, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };
  handleSubmitUpdate = data => {
    const { loadConfigListToUpdate } = this.props;
    const { id } = this.state;
    const params = { data, id };

    loadConfigListToUpdate(params, () => {
      if (!this.isErrorDefined()) {
        this.handleCreateModalClose();
      }
    });
  };

  render () {
    const {
      isEditModalOpen,
      initialValues,
      createEditHeaderContent,
      createEditBtnContent,
      handleCreateEditAction
    } = this.state;
    const { isDataLoading, isRequestLoading, configList, error } = this.props;
    const contentHeaderActions = [
      {
        content: 'Create new user email configuration',
        type: 'standardAdd',
        action: this.handleCreateModalOpen,
        floated: 'right'
      }
    ];
    const actionsList = [
      {
        content: 'Edit',
        type: 'standardEdit',
        action: this.handleEditModalOpen
      }
    ];
    const fieldList = [
      {
        type: 'string',
        label: 'Name',
        name: 'name',
        required: true
      },
      {
        type: 'string',
        label: 'Email address',
        name: 'from_email_address',
        required: true
      },
      {
        type: 'string',
        label: 'Sender name',
        name: 'from_sender_name',
        required: true
      }
    ];

    const tableHeaderLabels = [
      {
        name: 'id',
        label: 'ID'
      },
      {
        name: 'date_created',
        label: 'Date Created'
      },
      {
        name: 'from_email_address',
        label: 'From Email Address'
      },
      {
        name: 'from_sender_name',
        label: 'From Sender Name'
      },
      {
        name: 'last_updated',
        label: 'Last Updated'
      },
      {
        name: 'name',
        label: 'Name'
      }
    ];

    return (
      <DashboardTemplate>
        <TableView
          handleCreateModalClose={this.handleCreateModalClose}
          handleCreateEditAction={handleCreateEditAction}
          isEditModalOpen={isEditModalOpen}
          createEditHeaderContent={createEditHeaderContent}
          createEditBtnContent={createEditBtnContent}
          fieldList={fieldList}
          initialValues={initialValues}

          tableHeaderLabels={tableHeaderLabels}
          breadCrumbContent={['User email configurations']}
          contentHeaderActions={contentHeaderActions}

          tableActionsList={actionsList}
          dataList={configList}
          isDataLoading={isDataLoading}
          isRequestLoading={isRequestLoading}
          error={error}
        />
      </DashboardTemplate>
    );
  }
}

Markets.propTypes = {
  loadSelectedConfigList: PropTypes.func.isRequired,
  loadCreateConfigList: PropTypes.func.isRequired,
  loadConfigListToUpdate: PropTypes.func.isRequired,
  loadConfigList: PropTypes.func.isRequired,
  loadResetError: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  configList: PropTypes.array.isRequired
};

export default Markets;
